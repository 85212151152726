import React from 'react'
import './NRDRUHSFooter.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logoImage from './UttamSindhiKalakarLogoNew9.png'; // Import the logo image


function NRDRUHSFooter() {
    return (
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Services</h3>
                            <ul>
                                <li><a href="#">Registration</a></li>
                                <li><a href="#">Search Article</a></li>
                                <li><a href="#">Buy Subscription</a></li>
                                <li><a href="#">Technical Support</a></li>

                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Links</h3>
                            <ul>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Cancellation / Refund Policy​</a></li>
                                <li><a href="#">Terms and Conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li>




                            </ul>
                        </div>
                        <div className="col-md-6 item text">
                            <img src={logoImage} alt="Logo" className="logo" />

                            <p style={{ paddingTop: 21 }}>An Official Publication of Rajasthan University for Health Sciences.</p>
                        </div>
                        <div className="col item social">
                            <a href="#"><FontAwesomeIcon icon={faYoutube} /></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                    </div>

                </div>



            </footer>


        </div>





    )
}

export default NRDRUHSFooter;