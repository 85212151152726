import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './UserProfile.css';
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';
import ArticleStatus from './ArticleStatus';
import ArticlePublished from './ArticlePublished';
import ArticleWithdrawal from './ArticleWithdrawal';
import ArticleWithdrawalStatus from './ArticleWithdrawalStatus';

const UserProfile = () => {
    const [activeTab, setActiveTab] = useState('about');
    const [user, setUser] = useState(null);
    const [aboutArtistContent, setAboutArtistContent] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('token');

        if (!isAuthenticated) {
            // navigate('/login');
        } else {
            axios
                .get('https://nrdruhs.online/user/profile', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                })
                .then((response) => {
                    setUser(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching user profile:', error);
                });
        }
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleEditProfile = () => {
        navigate('/edituserprofile', { state: { user } });
    };

    const handleEditProfileAvatar = () => {
        navigate('/uploadavatar', { state: { user } });
    };

    return (
        <div>
            <UttamKalakarHeader />
            <div className="container mt-9 user-profile-container">
                <div className="row">
                    {/* Left Column */}
                    <div className="col-md-4">
                        <div className="card user-card">
                            {user ? (
                                <div className="card-body">
                                    <p className="card-text"><strong>ID - </strong>{user.id}</p>
                                    <img
                                        src={`https://nrdruhs.online/uploads/` + user.image}
                                        alt={`Profile picture of ${user.name}`}
                                        className=""
                                        style={{ width: 144, height: 144, borderRadius: '50%' }}
                                    />
                                    <p className="card-text"><strong>Name - </strong>{user.name}</p>
                                    <button className="btn btn-success btn-block mb-2 w-100" onClick={handleEditProfile}>Update Profile</button>
                                    <button className="btn btn-success btn-block mb-2 w-100" onClick={handleEditProfileAvatar}>Update Image Avatar</button>
                                </div>
                            ) : (
                                <p className="text-center">Loading user profile...</p>
                            )}
                        </div>
                    </div>
                    {/* Right Column */}
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                {user ? (
                                    <figure className="text-center mb-0">
                                        <blockquote className="blockquote">
                                            <p className="pb-3">
                                                <i className="fas fa-quote-left fa-xs text-primary"></i>
                                                <span className="lead font-italic">{user.aboutartist}</span>
                                                <i className="fas fa-quote-right fa-xs text-primary"></i>
                                            </p>
                                        </blockquote>
                                        <figcaption className="blockquote-footer mb-0">{user.name}</figcaption>
                                    </figure>
                                ) : null}
                            </div>
                        </div>
                        <div className="tab-container">
                            <ul className="nav user-profile-tab">
                                <li className={`nav-item ${activeTab === 'about' ? 'active' : ''}`}>
                                    <button className="nav-link" onClick={() => handleTabClick('about')}><i className="fas fa-user" style={{ paddingRight: 6 }}></i>About</button>
                                </li>
                                <li className={`nav-item ${activeTab === 'articles' ? 'active' : ''}`}>
                                    <button className="nav-link" onClick={() => handleTabClick('articles')}><i className="fas fa-images" style={{ paddingRight: 6 }}></i>Articles</button>
                                </li>
                                <li className={`nav-item ${activeTab === 'publishedarticles' ? 'active' : ''}`}>
                                    <button className="nav-link" onClick={() => handleTabClick('publishedarticles')}><i className="fas fa-images" style={{ paddingRight: 6 }}></i>Published</button>
                                </li>
                                <li className={`nav-item ${activeTab === 'withdrawal' ? 'active' : ''}`}>
                                    <button className="nav-link" onClick={() => handleTabClick('withdrawal')}><i className="fas fa-images" style={{ paddingRight: 6 }}></i>Withdrawal Request</button>
                                </li>
                                <li className={`nav-item ${activeTab === 'rstatus' ? 'active' : ''}`}>
                                    <button className="nav-link" onClick={() => handleTabClick('rstatus')}><i className="fas fa-images" style={{ paddingRight: 6 }}></i>Status</button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                {activeTab === 'about' && (
                                    <div className="tab-pane active show">
                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>Name</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.name : 'Loading user information...'}
                                            </div>
                                        </div>

                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>Category</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.category : 'Loading user information...'}
                                            </div>
                                        </div>


                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>Gender</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.gender : 'Loading user information...'}
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>Mobile Number</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.mobilenumber : 'Loading user information...'}
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>Address</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.address : 'Loading user information...'}
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>State</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.state : 'Loading user information...'}
                                            </div>
                                        </div>
                                        <div className="row" style={{ padding: '15px' }}>
                                            <div className="col-md-3" style={{ backgroundColor: '#ffffff50', color: 'black', padding: 6, textAlign: 'left' }}>
                                                <strong>City</strong>
                                            </div>
                                            <div className="col-md-9" style={{ backgroundColor: '#ffffff20', padding: 6 }}>
                                                {user ? user.city : 'Loading user information...'}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'articles' && (
                                    <div className="tab-pane active show">
                                        <div >

                                            <ArticleStatus />
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'publishedarticles' && (
                                    <div className="tab-pane active show">
                                        <div>
                                            <ArticlePublished />
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'withdrawal' && (
                                    <div className="tab-pane active show">
                                        <div>
                                            <ArticleWithdrawal />
                                        </div>
                                    </div>
                                )}
                                {activeTab === 'rstatus' && (
                                    <div className="tab-pane active show">
                                        <div>
                                            <ArticleWithdrawalStatus />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: 60 }}></div>
            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>
        </div>
    );
};

export default UserProfile;
