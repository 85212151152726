import React, { useState, useEffect } from 'react';
import myHeaderVideo from './nrdruhsheader.mp4';
import './Home.css';
import UttamKalakarFooter from './UttamKalakarFooter';
import buyNowImage from './UttamKalakarBuyNowImg.png'; // Import the logo image
import ManuScriptSlider from './ManuScriptSlider';




function Home() {


    const [scrolling, setScrolling] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    // Function to handle the click event of the "Scroll to Top" button
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div >

            {/* Header Banner */}
            <div className="header-container">
                <video autoPlay muted loop className="fullscreen-video">
                    <source src={myHeaderVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="video-overlay"></div> {/* Overlay added here */}
                <div className="video-title">
                    <span style={{ fontSize: 12 }}></span><span className="outline-text small-font">WELCOME TO<br /></span>
                    <span style={{ fontSize: 30 }}></span><span className="outline-text larger-font">NATIONAL RESEARCH DENTICON</span>
                </div>
                <p className="video-subtitle" style={{ fontSize: 15, paddingTop: 3, fontWeight: 300, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', color: 'white', background: 'red', paddingLeft: 3, paddingRight: 3 }}>
                    <strong>An Official Publication of Rajasthan University for Health Sciences</strong>
                </p>

            </div>




            <div className="row" style={{ margin: 0, paddingBottom: 45, marginTop: -86, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)', overflowX: 'hidden' }}>
                <div className="col-lg-8 col-md-12" data-aos="fade-up" data-aos-duration="1200" style={{ paddingLeft: 45, paddingRight: 45, textAlign: 'justify' }}>
                    <div className="card card-with-shadow" style={{ background: 'linear-gradient(to bottom, #0033ff, #003366)', padding: '45px', borderRadius: '10px' }}>
                        <h3 style={{ paddingTop: 30, fontSize: 27, fontWeight: 'bold', paddingBottom: 18, color: 'white' }}>Message from Team Editorial Board</h3>
                        <p style={{ color: 'white' }}>
                            "Imagination is more important than knowledge. Knowledge is limited, imagination encircles the world" - Albert Einstein
                            <br /><br />
                            Encompassing the great thought by a great scientist, the pathway of research is laid. Research is to see what everybody else has seen, and to think what nobody else has thought. The key is to keep oneself motivated to find what is not seen and enhance what is observed.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 main-image-container" data-aos="fade-right" data-aos-duration="1800" style={{ paddingTop: 0, paddingLeft: 15, paddingRight: 15, textAlign: 'center' }}>
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <div className="mb-2" style={{ width: '100%', maxWidth: '340px', paddingBottom: 21 }}>
                            <button type="button" className="btn btn-success btn-lg btn-block" style={{ width: '100%', padding: '1.5rem' }}>
                                <i className="fas fa-user-plus" style={{ paddingRight: 9, fontSize: 30, verticalAlign: 'middle' }} /> Get Registered
                            </button>
                        </div>
                        <div className="mb-2" style={{ width: '100%', maxWidth: '340px', paddingBottom: 21 }}>
                            <button type="button" className="btn btn-danger btn-lg btn-block" style={{ width: '100%', padding: '1.5rem' }}>
                                <i className="fas fa-cloud-upload-alt" style={{ paddingRight: 9, fontSize: 30, verticalAlign: 'middle' }} /> Submit Your Article
                            </button>
                        </div>
                        <div style={{ width: '100%', maxWidth: '340px' }}>
                            <button type="button" className="btn btn-primary btn-lg btn-block" style={{ width: '100%', padding: '1.5rem' }}>
                                <i className="fas fa-search" style={{ paddingRight: 9, fontSize: 30, verticalAlign: 'middle' }} /> Find An Article
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row" style={{ height: 560, margin: 0, backgroundImage: 'url(https://nrdruhs.online/uploads/uttamkalakarbgnew.jpg)', backgroundSize: 'cover' }}>
                <h3 style={{ fontSize: 30, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)', textAlign: 'center', paddingTop: 90, paddingBottom: 0, color: 'white', fontWeight: 'bold' }}>OUR PUBLISHED ARTICLES</h3>
                <ManuScriptSlider />
            </div>


            <div className="row" style={{ background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)', margin: 0, minHeight: '300px' }}>
                <div className="col-sm-12 col-md-4 d-flex align-items-center" style={{ paddingLeft: 30 }}>
                    <p style={{ color: 'white', fontSize: '18px', paddingTop: 30 }}>
                        <h3><strong>SUBMIT YOUR ARTICLE</strong></h3>
                        Register with us to submit your Article!
                    </p>
                </div>

                <div className="col-sm-6 col-md-3 d-flex justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                    <button type="button" className="btn btn-primary btn-block" style={{ padding: '15px', fontSize: '18px', fontWeight: 'bold' }}>REGISTER NOW!</button>
                </div>


                <div className="col-sm-6 col-md-4">
                    <div style={{ paddingTop: 30, height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', overflow: 'hidden', marginBottom: '-100px' }}>
                        <img src={buyNowImage} alt="buynow" className="buynow zoom" style={{ maxHeight: '96%', maxWidth: '96%', height: 'auto', objectFit: 'contain' }} />
                    </div>
                </div>
            </div>










            <div className="parallax-row" >
                <div className="container" >
                    <div className="row justify-content-center" style={{ padding: 15 }}>
                        <div className="col-md-4" data-aos="fade-down" data-aos-duration="1200" >
                            <div className="column">
                                <i className="fas fa-globe" style={{ paddingTop: 30, paddingBottom: 30, fontSize: 30, verticalAlign: 'middle' }} />
                                <h3>Worldwide Articles</h3>
                                <p>Authors from All Over World</p>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-up" data-aos-duration="1200" >
                            <div className="column">

                                <i className="fa-solid fa-magnifying-glass" style={{ paddingTop: 30, paddingBottom: 30, fontSize: 30, verticalAlign: 'middle' }} />
                                <h3>Search An Article</h3>
                                <p>Multiple Search Filters Categories</p>
                            </div>
                        </div>
                        <div className="col-md-4" data-aos="fade-down" data-aos-duration="1200" >
                            <div className="column">
                                <i className="fas fa-user-plus" style={{ paddingTop: 30, paddingBottom: 30, fontSize: 30, verticalAlign: 'middle' }} />
                                <h3>Register</h3>
                                <p>As Author | Reviewer </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <UttamKalakarFooter />

            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>

            </div>

            {/* Scroll to Top Button */}
            <button
                className={`scroll-to-top-btn ${scrolling ? 'show' : ''}`}
                onClick={handleScrollToTop}
                title="Scroll to Top"
            >
                <i className="fas fa-arrow-up"></i>
            </button>


        </div >
    )
}

export default Home