import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import UttamKalakarFooter from './UttamKalakarFooter';

const AddArticleIssue = () => {
    const [articleVolIssue, setArticleVolIssue] = useState('');
    const [articleVolIssuesList, setArticleVolIssuesList] = useState([]);
    const [newArticleVolIssue, setNewArticleVolIssue] = useState('');

    useEffect(() => {
        fetchArticleVolIssues();
    }, []);

    const fetchArticleVolIssues = async () => {
        try {
            const response = await axios.get('https://nrdruhs.online/api/fetcharticleissue');
            if (response.status === 200) {
                setArticleVolIssuesList(response.data);
            } else {
                console.error('Failed to fetch article volume issues');
            }
        } catch (error) {
            console.error('Error fetching article volume issues:', error);
        }
    };

    const handleSave = async () => {
        try {
            const selectedIssue = articleVolIssue || newArticleVolIssue;
            if (!selectedIssue) {
                alert('Please select or enter an article issue.');
                return;
            }

            const response = await axios.post('https://nrdruhs.online/api/articleissue', { articleVolIssue: selectedIssue });
            if (response.status === 200) {
                Swal.fire({
                    title: 'Success',
                    text: 'Article issue added successfully',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
                setArticleVolIssue('');
                setNewArticleVolIssue('');
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to add article issue',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error adding article issue:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error adding article issue',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div className="custom-container">
            <div className="main-content">
                <img
                    src="/assets/NRDRUHSHomeBanner.jpg"
                    alt="Background"
                    className="header-image"
                    style={{ width: '100%', height: '60vh', objectFit: 'cover' }}
                />
            </div>

            <div className="row justify-content-center mt-4" style={{ marginRight: 20 }}>
                <div className="col-lg-4 col-md-6 col-sm-8 col-10">
                    <div className="card p-4 shadow" style={{ background: '#B3E7FF' }}>
                        <h2 className="mb-4">Add Article Issue</h2>
                        <div className="mb-3">
                            <label htmlFor="articleVolIssue" className="form-label">Select Article Volume Issue or Enter New Issue</label>
                            <select
                                className="form-select"
                                id="articleVolIssue"
                                value={articleVolIssue}
                                onChange={(e) => setArticleVolIssue(e.target.value)}
                            >
                                <option value="">Select Article Volume Issue</option>
                                {articleVolIssuesList.map((issue, index) => (
                                    <option key={index} value={issue}>{issue}</option>
                                ))}
                            </select>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="newArticleVolIssue"
                                    value={newArticleVolIssue}
                                    onChange={(e) => setNewArticleVolIssue(e.target.value)}
                                    placeholder="Or enter a new issue"
                                />
                            </div>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>

            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>
        </div>
    );
};

export default AddArticleIssue;
