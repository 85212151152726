import React from 'react';
import { Card, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import { FaFileUpload, FaInfo, FaUsers, FaCheck } from 'react-icons/fa'; // Import Font Awesome icons
import NRDRUHSHeader from './NRDRUHSHeader';
import NRDRUHSFooter from './NRDRUHSFooter';

const AuthorReviewFinalSubmit = () => {
    return (
        <div>
            <NRDRUHSHeader />

            <div className="container mt-5" style={{ marginTop: 60, marginBottom: 60 }}>
                <h2 className="mb-4 text-center">Author Review and Final Submit</h2>

                <Row>
                    {/* Document Upload */}
                    <Col md={6} lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <FaFileUpload size={40} className="mb-3 text-primary" />
                                <Card.Title>Document Upload</Card.Title>
                                <Card.Text>
                                    Upload your document here.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Type, Title and Abstract */}
                    <Col md={6} lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <FaInfo size={40} className="mb-3 text-success" />
                                <Card.Title>Type, Title and Abstract</Card.Title>
                                <Card.Text>
                                    Provide type, title, and abstract.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Authors And Institutions */}
                    <Col md={6} lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <FaUsers size={40} className="mb-3 text-warning" />
                                <Card.Title>Authors And Institutions</Card.Title>
                                <Card.Text>
                                    Add authors and institutions.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Author Review and Final Submit */}
                    <Col md={6} lg={3} className="mb-4">
                        <Card>
                            <Card.Body>
                                <FaCheck size={40} className="mb-3 text-danger" />
                                <Card.Title>Author Review and Final Submit</Card.Title>
                                <Card.Text>
                                    Review and submit your work.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <NRDRUHSFooter />
        </div>
    );
};

export default AuthorReviewFinalSubmit;
