import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './ListArtists.css';
import Swal from 'sweetalert2'; // Import SweetAlert2
import UttamKalakarHeader from './UttamKalakarHeader';
import uttamKalakarFooter from './UttamKalakarFooter';
import UttamKalakarFooter from './UttamKalakarFooter';

function ListArticlesPublish() {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/listarticlepublish')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const handleToggle = (index) => {
        Swal.fire({
            title: 'Confirm',
            text: 'Do you want to enable/disable this article?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedData = [...data];
                updatedData[index].is_published = updatedData[index].is_published ? 0 : 1;
                setData(updatedData);

                const articleId = updatedData[index].articleid; // Use 'id' to access articleId
                const updatedValue = updatedData[index].is_published;
                axios
                    .post('https://nrdruhs.online/updatearticlepublish', { id: articleId, is_published: updatedValue })
                    .then((res) => {
                        // Handle the successful update if needed
                        Swal.fire('Success', 'The manuscript has been updated.', 'success');
                    })
                    .catch((err) => {
                        console.log(err);
                        // Handle the error if needed
                        Swal.fire('Error', 'An error occurred while updating the manuscript.', 'error');
                    });
            }
        });
    };

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/articlenewsearch', {
                params: { search: searchTerm },
            })
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, [searchTerm]);

    // Filter the data based on the search term
    const filteredData = data.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (

        <div style={{ background: 'white' }}>
            <UttamKalakarHeader />

            <div style={{ paddingLeft: 60, paddingRight: 60, paddingBottom: 60 }}>

                <h2 className="text-center" style={{ paddingTop: 60 }}>Publish An Article</h2>
                <div className="container-fluid px-0" >
                    <div className="px-5 py-3">
                        <div className='d-flex justify-content-between align-items-center mt-2'>
                            <h3 style={{ paddingBottom: 15 }}>
                                <strong></strong>
                            </h3>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='me-3'>
                                <label className='form-label me-1' style={{ fontWeight: 'bold' }}>Search Name</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Search by name'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mt-3" data-aos="fade-up" data-aos-duration="1800">
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center', backgroundColor: '#007bff', color: 'white' }}>Article Id</th>
                                        <th style={{ backgroundColor: '#007bff', color: 'white' }}>Title</th>
                                        <th style={{ textAlign: 'center', backgroundColor: '#007bff', color: 'white' }}>Published</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((article, index) => (
                                        <tr key={index}>
                                            <td style={{ verticalAlign: 'middle', fontWeight: 'bold', textAlign: 'center', width: '9%' }}>{article.articleid}</td>
                                            <td style={{ verticalAlign: 'middle', width: '72%' }}>{article.title}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center', width: '19%' }}>
                                                <label className="switch">
                                                    <input type="checkbox" checked={article.is_published === 1} onChange={() => handleToggle(index)} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <UttamKalakarFooter />
        </div>

    );
}

export default ListArticlesPublish;
