import React from 'react'
import './NewBanner.css'


function NewBanner() {
    return (
        <div>


            Pradeep Khemnani



        </div>
    )
}

export default NewBanner