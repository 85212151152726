import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './ListUsers.css';
import logoImage from './UttamSindhiKalakarLogoNew9.png';
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function ListUsersCards() {
    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedGender, setSelectedGender] = useState(''); // Set initial value to an empty string
    const [genderOptions, setGenderOptions] = useState([]); // Add this state

    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(8); // Set the number of cards per page

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/listuserscardscategory/category')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setCategoryOptions(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));

        // Fetch gender options
        axios
            .get('https://nrdruhs.online/listuserscardsgender/gender')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setGenderOptions(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, []); // No dependencies needed here

    useEffect(() => {
        fetchUsersData();
    }, [selectedCategory, selectedGender]);

    const fetchUsersData = () => {
        let params = {};

        if (selectedCategory !== '') {
            params = { ...params, category: selectedCategory };
        }

        // Check for an empty string to show all genders
        if (selectedGender !== '') {
            params = { ...params, gender: selectedGender };
        }

        axios
            .get('https://nrdruhs.online/listuserscardscategory', { params })
            .then((res) => {
                if (res.data.Status === 'Success') {
                    let filteredData = res.data.Result.filter((author) => {
                        // Check for an empty string to show all genders
                        if (selectedCategory === '' && selectedGender === '') {
                            return true;
                        }

                        const categories = author.category.split(', ');
                        const isCategoryMatch = selectedCategory === '' || categories.includes(selectedCategory);
                        const isGenderMatch = selectedGender === '' || author.gender === selectedGender;

                        return isCategoryMatch && isGenderMatch;
                    });

                    setData(filteredData);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    };

    const [scrolling, setScrolling] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        AOS.init();
    }, []);

    // Calculate the index range of cards for the current page
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = data.slice(indexOfFirstCard, indexOfLastCard);

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate total number of pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.length / cardsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="custom-container">

            <UttamKalakarHeader />

            <div className="px-5 py-3">

                <div className="d-flex justify-content-center mt-2">
                    <h3>
                        <strong>List of Artists - 2023</strong>
                    </h3>
                </div>
                <div className="mt-3" >
                    <div className="d-flex justify-content-end mb-3">



                        {/* Category Dropdown */}
                        <select
                            style={{
                                padding: '12px',
                                borderRadius: '4px',
                                color: '#fff',
                                fontSize: '18px',
                                backgroundColor: '#0066ff'
                                // Add any additional styles you want
                            }}
                            value={selectedCategory}
                            onChange={(event) => setSelectedCategory(event.target.value === 'All Categories' ? '' : event.target.value)}
                        >
                            <option value="All Categories">All Categories</option>
                            {categoryOptions.map((categories, index) => {
                                // Split the "categories" string by comma (or your chosen delimiter)
                                const individualCategories = categories.split(', ');

                                // Map over the individual categories and create an option for each
                                return (
                                    <React.Fragment key={index}>
                                        {individualCategories.map((category, subIndex) => (
                                            <option key={subIndex} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </select>
                        {/* Add a gap */}
                        <div style={{ width: '20px' }}></div> {/* Adjust the width as needed for the desired gap */}


                        {/* Gender Dropdown */}
                        <select
                            style={{
                                padding: '12px',
                                borderRadius: '4px',
                                color: '#fff',
                                fontSize: '18px',
                                backgroundColor: '#0066ff',
                            }}
                            value={selectedGender}
                            onChange={(event) => setSelectedGender(event.target.value === 'All Genders' ? '' : event.target.value)}
                        >
                            <option value="All Genders">All Genders</option>
                            {genderOptions.map((gender, index) => (
                                <option key={index} value={gender}>
                                    {gender}
                                </option>
                            ))}
                        </select>



                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4">
                        {currentCards.map((author, index) => (
                            <div className="col" key={index}>
                                <div className="card" style={{ margin: '10px' }}>
                                    <div className="picture">
                                        <img
                                            src={`https://nrdruhs.online/uploads/` + author.image}
                                            alt="authorImg"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="team-content">
                                        <h5 className="card-title" style={{ fontSize: 16, fontWeight: 'bold', margin: '4px 0' }}>
                                            {author.name}
                                        </h5>
                                        <p className="card-text" style={{ fontSize: 12, margin: '4px 0', paddingBottom: 20 }}>
                                            {author.category}
                                        </p>
                                        <ul className="social" style={{ paddingTop: 3, paddingBottom: 12 }}>
                                            <Link to={`/userprofilesubscriber/${author.id}`} className="btn btn-primary btn-block">More</Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                    {/* Pagination */}

                    <ul className="pagination justify-content-center" style={{ paddingTop: 30 }}>
                        {/* First Page Button */}
                        <li className="page-item">
                            <button onClick={() => paginate(1)} className="page-link">
                                &lt;&lt;
                            </button>
                        </li>

                        {/* Previous Page Button */}
                        <li className="page-item">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                            >
                                Previous
                            </button>
                        </li>

                        {/* Page Numbers */}
                        {pageNumbers.map((number) => (
                            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                <button onClick={() => paginate(number)} className="page-link">
                                    {number}
                                </button>
                            </li>
                        ))}

                        {/* Next Page Button */}
                        <li className="page-item">
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                className={`page-link ${currentPage === pageNumbers.length ? 'disabled' : ''}`}
                            >
                                Next
                            </button>
                        </li>

                        {/* Last Page Button */}
                        <li className="page-item">
                            <button onClick={() => paginate(pageNumbers.length)} className="page-link">
                                &gt;&gt;
                            </button>
                        </li>
                    </ul>

                </div>

                <div style={{ padding: 60 }}></div>

            </div>

            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>


        </div>
    );
}

export default ListUsersCards;
