import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './InboxMessages.css';
import { FaRegEnvelopeOpen } from 'react-icons/fa';
import UttamKalakarHeader from './UttamKalakarHeader'; // Adjust the path accordingly
import UttamKalakarFooter from './UttamKalakarFooter'; // Adjust the path accordingly


function formatDate(dateString) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
}

function InboxMessages() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [expandedMessages, setExpandedMessages] = useState([]);


    const loggedInUser = sessionStorage.getItem('loggedInUser');

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/myinboxmessages')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    const inboxMessages = res.data.Result;
                    const emails = inboxMessages.map((message) => message.messagefrom);

                    // Filter out messages from loggedInUser
                    const filteredEmails = emails.filter((email) => email !== loggedInUser);

                    axios
                        .get('https://nrdruhs.online/usersnew', {
                            params: { emails: filteredEmails.join(',') }
                        })
                        .then((res) => {
                            if (res.data.Status === 'Success') {
                                const users = res.data.Result;
                                const dataWithNames = inboxMessages
                                    .filter((message) => message.messagefrom !== loggedInUser)
                                    .map((message) => {
                                        const user = users.find((user) => user.email === message.messagefrom);
                                        return {
                                            ...message,
                                            name: user ? user.name : ''
                                        };
                                    });
                                setData(dataWithNames);
                                setExpandedMessages(Array(dataWithNames.length).fill(false));
                            } else {
                                alert('Error');
                            }
                        })
                        .catch((err) => console.log(err));
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, [loggedInUser]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const toggleMessage = (index) => {
        setExpandedMessages((prevExpandedMessages) => {
            const newExpandedMessages = [...prevExpandedMessages];
            newExpandedMessages[index] = !newExpandedMessages[index];
            return newExpandedMessages;
        });
    };

    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }
        return (



            <nav aria-label="...">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            tabIndex="-1"
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
                        <li
                            className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                            key={pageNumber}
                        >
                            <button className="page-link" onClick={() => paginate(pageNumber)}>
                                {pageNumber}
                            </button>
                        </li>
                    ))}
                    <li
                        className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}
                    >
                        <button
                            className="page-link"
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === pageNumbers.length}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        );
    };

    const currentItemsToShow = data.slice(indexOfFirstItem, indexOfLastItem);

    return (

        <div style={{ background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
            <UttamKalakarHeader />


            <div className="container-fluid px-0" style={{ marginTop: 30, paddingLeft: 60, paddingRight: 60 }}>

                <div className="px-5 py-3">
                    <div
                        className="d-flex justify-content-center mt-2"
                        data-aos="fade-down"
                        data-aos-duration="1200"
                    >
                        <h3>
                            <strong>Message Inbox</strong>
                        </h3>
                    </div>

                    <div className="mt-3" data-aos="fade-up" data-aos-duration="1800">
                        <table className="table w-100">
                            <colgroup>
                                <col style={{ width: '27%' }} />
                                <col style={{ width: '18%' }} />
                                <col style={{ width: '50%' }} />
                                <col style={{ width: '5%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>Date</th>
                                    <th></th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItemsToShow.map((activitynew, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>
                                                <span style={{ paddingRight: 12, fontSize: 18, color: '#ccc' }}>
                                                    <FaRegEnvelopeOpen />
                                                </span>
                                                {activitynew.name}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {formatDate(activitynew.messagedate)}
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                {expandedMessages[index] && (
                                                    <div className="accordion" id={`messageAccordion_${index}`}>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id={`messageHeading_${index}`}>
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#messageCollapse_${index}`}
                                                                    aria-expanded="true"
                                                                    aria-controls={`messageCollapse_${index}`}
                                                                >
                                                                    Message
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id={`messageCollapse_${index}`}
                                                                className="accordion-collapse collapse show"
                                                                aria-labelledby={`messageHeading_${index}`}
                                                                data-bs-parent={`#messageAccordion_${index}`}
                                                            >
                                                                <div className="accordion-body">{activitynew.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={() => toggleMessage(index)}
                                                >
                                                    {expandedMessages[index] ? 'Less' : 'More'}
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {renderPagination()}
                </div>
            </div>
            <div style={{ marginBottom: 90 }}> </div>
            <UttamKalakarFooter />

        </div>
    );
}

export default InboxMessages;
