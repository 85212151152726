import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ManuScriptSlider.css'; // Import custom CSS for styling

function ManuScriptSlider() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Fetch image URLs, titles, and filenames from the backend
        axios.get('https://nrdruhs.online/getimagesfromarticles')
            .then(response => {
                setImages(response.data);
            })
            .catch(error => {
                console.error('Error fetching images:', error);
            });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 4, // Show 4 images at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="container-fluid" style={{ padding: 60, marginTop: -30 }}>
            <div className="row">
                <div className="col">
                    <Slider {...settings}>
                        {Array.isArray(images) && images.length > 0 ? (
                            images.map(image => (
                                <div key={image.id} style={{ textAlign: 'center' }}>
                                    <a href={`https://nrdruhs.online/uploads/${image.coverpage}`} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={`https://nrdruhs.online/uploads/${image.image}`}
                                            alt={image.altText}
                                            className="slider-image"
                                        />
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p>No images available</p> // Optionally display a message when there are no images
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );

}

export default ManuScriptSlider;
