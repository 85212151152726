import React from 'react';
import './UploadAvatar.css'; // Import your CSS file
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';

function ContactUs() {
    return (
        <div>
            <UttamKalakarHeader />

            <div className="container" style={{ paddingTop: 60 }}>
                <h2 className="text-center mb-4" style={{ fontWeight: 'bold' }}>
                    Contact Us
                </h2>
            </div>

            <div className="container">
                <div className="row" style={{ marginBottom: 120 }}>
                    <div
                        className="col-md-4"
                        style={{ padding: 30 }}

                    ></div>
                    <div
                        className="col-md-4"
                        style={{ padding: 15, textAlign: 'center' }}
                        data-aos="fade-down"
                        data-aos-duration="1200"
                    >
                        <p style={{ textAlign: 'center', lineHeight: 1.5 }}>
                            <strong>
                                <i className="fa fa-map-marker" style={{ fontSize: 30, color: '#0099ff' }}></i><br />
                            </strong>
                            <br />
                            <strong>UTTAM SINDHI FOUNDATION</strong>
                            <br />
                            Opp. Car World Showroom,
                            <br />
                            Kalyan Ambernath Road,
                            <br />
                            Shanti Nagar, Ulhasnagar 421003
                            <br />
                            Mumbai, Maharashtra, INDIA
                            <br />
                            <br />
                            <strong>
                                <i className="fa fa-phone" style={{ fontSize: 30, color: '#0099ff' }}></i><br />
                            </strong>
                            <br />
                            +91-8308100333 / 9829061727
                            <br />
                            <strong>For Technical Queries<br /> Contact:</strong>
                            <br />
                            Pradeep Khemnani on +91-9772744738
                            <br />
                            <br />
                            <strong>
                                <i className="fa fa-envelope" style={{ fontSize: 30, color: '#0099ff' }}></i>
                            </strong>
                            <br />
                            uttamsindhikalakar@gmail.com
                        </p>
                    </div>

                    <div
                        className="col-md-4"
                        style={{ padding: 15, textAlign: 'center' }}

                    ></div>
                </div>
            </div>

            <UttamKalakarFooter />
        </div>
    );
}

export default ContactUs;
