import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NRDRUHSHeader from './NRDRUHSHeader';
import Swal from 'sweetalert2';
import NRDRUHSFooter from './NRDRUHSFooter';

const UpdateAuthors = () => {

  const [authorData, setAuthorData] = useState({
    name: '',
    email: '',
    mobilenumber: '',
    address: '',
    country: '',
    state: '',
    city: '',
    institutionname: '',
    selectedTitle: ''
  });



  const [authorsList, setAuthorsList] = useState([]);

  const [titlesList, setTitlesList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthorData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const selectedTitle = authorData.selectedTitle;
      const selectedTitleObject = titlesList.find((titleObject) => titleObject.title === selectedTitle);

      if (selectedTitleObject) {
        const response = await axios.post('https://nrdruhs.online/addauthor', {
          ...authorData,
          articleid: selectedTitleObject.articleid,
        });

        setAuthorsList((prevList) => [...prevList, response.data.author]);

        setAuthorData({
          name: '',
          email: '',
          mobilenumber: '',
          address: '',
          country: '',
          state: '',
          city: '',
          institutionname: '',
          selectedTitle: '', // Reset selectedTitle field
        });

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Author data saved successfully',
        });
      }
    } catch (error) {
      console.error('Error saving author data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while saving author data',
      });
    }
  };





  // ... (previous code)

  const handleDeleteAuthor = async (authorId) => {
    try {
      await axios.delete(`https://nrdruhs.online/deleteauthor/${authorId}`);
      setAuthorsList((prevList) =>
        prevList.filter((author) => author.id !== authorId)
      );
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Author deleted successfully',
      });
    } catch (error) {
      console.error('Error deleting author:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the author',
      });
    }
  };



  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const userId = sessionStorage.getItem('userId');
        const response = await axios.get(`https://nrdruhs.online/manuscripttitlesuser?userId=${userId}`);
        setTitlesList(response.data.titles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchTitles();
  }, []); // Empty dependency array ensures the effect runs only once on mount


  useEffect(() => {
    const checkScrollAndScrollToTop = () => {
      setTimeout(() => {
        // Check if the user has scrolled down
        if (window.scrollY > 0) {
          // If scrolled down, scroll to top
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, 1000); // Wait for 1 second before checking scroll position
    };

    checkScrollAndScrollToTop();

    // Cleanup function to prevent memory leaks
    return () => clearTimeout(checkScrollAndScrollToTop);
  }, []);



  return (

    <div style={{ backgroundColor: '#efefef' }}><NRDRUHSHeader />

      <div className="container mt-5" style={{ marginTop: 60, marginBottom: 60 }}>

        <h3 className="mt-2" style={{ textAlign: 'center', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, fontWeight: 'bold', backgroundColor: '#ff2056', color: 'white' }}>Step 3</h3>
        <br />
        <h2 className="mb-4 text-center" style={{ fontSize: 24, fontWeight: 'bold' }}>UPDATE AUTHORS & INSTITUTIONS</h2>


        {/* New dropdown input for titles */}
        <div className="mb-12 row" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          <label className="col-sm-12 col-form-label">Select Title</label>
        </div>
        <div className="mb-3 row justify-content-center" >
          <div className="col-sm-9" >
            <select
              className="form-select"
              name="selectedTitle"
              value={authorData.selectedTitle}
              onChange={handleChange}
              style={{ backgroundColor: 'darkblue', color: 'white', fontSize: 18 }} // Apply background and text color styles
            >
              <option value="">Select a title</option>
              {titlesList.map((titleObject) => (
                <option key={titleObject.title} value={titleObject.title}>
                  {titleObject.title} - {titleObject.articleid}
                </option>
              ))}
            </select>



          </div>
        </div>



        <form style={{ width: '48%', margin: 'auto', paddingTop: 60 }}>
          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>Name</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="name" value={authorData.name} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>Email</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="email" value={authorData.email} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>Mobile</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="mobilenumber" value={authorData.mobilenumber} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>Address</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="address" value={authorData.address} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>Country</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="country" value={authorData.country} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>State</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="state" value={authorData.state} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>City</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" name="city" value={authorData.city} onChange={handleChange} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label" style={{ fontWeight: 'bold' }}>Institution</label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="institutionname"
                name="institutionname"
                value={authorData.institutionname}
                onChange={handleChange}
              />
            </div>
          </div>



          <div className="text-center" style={{ marginTop: 45, marginBottom: 144 }}>
            <button type="button" className="btn btn-primary" onClick={handleSubmit} style={{ padding: 15, fontWeight: 'bold' }}>
              ADD AUTHOR & INSTITUTION
            </button>
          </div>
        </form>

        {authorsList.length > 0 && (
          <div className="mt-4">
            <h3 style={{ textAlign: 'center' }}>Authors List</h3>


            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>City</th>
                  <th>Action</th>



                </tr>
              </thead>
              <tbody>
                {authorsList.map((author) => (
                  <tr key={author.id}>
                    <td>{author.name}</td>
                    <td>{author.email}</td>
                    <td>{author.mobilenumber}</td>
                    <td>{author.city}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteAuthor(author.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>



          </div>
        )}
      </div>
      <NRDRUHSFooter />
    </div>
  );
};

export default UpdateAuthors;
