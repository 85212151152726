import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './MultiAuthorCard.css';

function MultiArtistCard() {
    const [data, setData] = useState([]);
    const [additionalMembers, setAdditionalMembers] = useState([]);
    const [additionalMembersCount, setAdditionalMembersCount] = useState(0);
    const [showAdditionalMembers, setShowAdditionalMembers] = useState(false);

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/pocardsadmin')
            .then((res) => {
                if (res.data.status === 'success') {
                    setData(res.data.result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/members/countadmin')
            .then((response) => {
                const count = response.data.count;
                setAdditionalMembersCount(count);
            })
            .catch((error) => {
                console.error('Error fetching additional members count:', error);
            });
    }, []);

    const handleAdditionalMembersClick = () => {
        setAdditionalMembers(data.slice(6));
        setShowAdditionalMembers(true);
    };

    return (
        <div className="col-sm-4" data-aos="fade-left" data-aos-duration="1800">
            <div className="card" style={{ textAlign: 'center', padding: 9, backgroundColor: 'white' }}>
                <div className="card-header">
                    <h4 className="card-title" style={{ fontSize: 21, color: 'black' }}>
                        Admin
                    </h4>
                </div>
                <div className="card-body">
                    <p style={{ marginTop: '15px', marginBottom: '30px' }}>Admin Users</p>
                    <div
                        className="avatar-group"
                        style={{
                            backgroundColor: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {data.slice(0, 6).map((author, index) => (
                            <a
                                href="#!.html"
                                className="avatar-group-item avatar sm rounded-circle"
                                data-tippy-placement="top"
                                data-tippy-content={author.name}
                                key={index}
                            >
                                <img
                                    src={`https://nrdruhs.online/uploads/${author.image}`}
                                    alt="..."
                                    className="avatar-group-img rounded-circle"
                                />
                            </a>
                        ))}
                        {showAdditionalMembers &&
                            additionalMembers.map((author, index) => (
                                <a
                                    href="#!.html"
                                    className="avatar-group-item avatar sm rounded-circle"
                                    data-tippy-placement="top"
                                    data-tippy-content={author.name}
                                    key={index}
                                >
                                    <img
                                        src={`https://nrdruhs.online/uploads/${author.image}`}
                                        alt="..."
                                        className="avatar-group-img rounded-circle"
                                    />
                                </a>
                            ))}
                        {additionalMembersCount > 6 && !showAdditionalMembers && (
                            <a
                                href="#!.html"
                                className="avatar-group-item d-flex align-items-center justify-content-center bg-gray text-body avatar sm rounded-circle"
                                data-tippy-placement="top"
                                data-tippy-content={`${additionalMembersCount - 6} more members`}
                                onClick={handleAdditionalMembersClick}

                            >
                                <b>+{additionalMembersCount - 6}</b>
                            </a>
                        )}
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: 30 }}>
                        <button type="button" className="btn btn-primary">
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultiArtistCard;
