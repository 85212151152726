import React, { useState } from 'react';
import ArticleUpload from './ArticleUpload';

const UpdateManuScript = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
    };

    const handlePrev = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
    };

    return (
        <div style={{ marginTop: 200 }}>
            <h2>Stepper Example</h2>
            <div className="stepper">
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${(currentStep - 1) * 33.33}%` }}></div>
                </div>
                <ul className="steps">
                    <li className={currentStep === 1 ? 'active' : ''}>
                        <div className="step-number">1</div>
                        <div className="step-description">ONE</div>
                        {console.log("Rendering ArticleUpload")}
                        <ArticleUpload />
                    </li>
                    <li className={currentStep === 2 ? 'active' : ''}>
                        <div className="step-number">2</div>
                        <div className="step-description">TWO</div>
                    </li>
                    <li className={currentStep === 3 ? 'active' : ''}>
                        <div className="step-number">3</div>
                        <div className="step-description">THREE</div>
                    </li>
                </ul>
            </div>

            {currentStep === 1 && (
                <div>
                    <h3>Step 1 - ONE</h3>
                    {/* Add content for Step 1 here */}
                </div>
            )}

            {currentStep === 2 && (
                <div>
                    <h3>Step 2 - TWO</h3>
                    {/* Add content for Step 2 here */}
                </div>
            )}

            {currentStep === 3 && (
                <div>
                    <h3>Step 3 - THREE</h3>
                    {/* Add content for Step 3 here */}
                </div>
            )}

            <div className="button-container">
                {currentStep !== 1 && (
                    <button type="button" className="btn btn-secondary" onClick={handlePrev}>
                        Previous
                    </button>
                )}
                {currentStep !== 3 && (
                    <button type="button" className="btn btn-primary" onClick={handleNext}>
                        Next
                    </button>
                )}
            </div>
        </div>
    );
};

export default UpdateManuScript;
