import React, { useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './UploadAvatar.css'; // Import your CSS file
import UttamKalakarHeader from './UttamKalakarHeader';
import Swal from 'sweetalert2'; // Import SweetAlert2

function UploadAvatar() {
    const [src, setSrc] = useState(null);
    const [preview, setPreview] = useState(null);
    const editorRef = React.useRef(null);
    const [image, setImage] = useState(null);

    const navigate = useNavigate();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        setSrc(URL.createObjectURL(file));
    };

    const onClose = () => {
        setPreview(null);
    };

    const onCrop = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImage();
            const dataURL = canvas.toDataURL();
            setPreview(dataURL);
        }
    };

    const saveCroppedImage = () => {
        if (!preview) {
            return;
        }

        const croppedImageData = dataURItoBlob(preview); // Convert data URI to Blob

        // Create a new file name with the desired extension (e.g., .jpg)
        const fileName = 'cropped_image.jpg';

        // Assuming you have userId stored in your component state
        const userId = sessionStorage.userId;

        // Construct the FormData with the userId
        const formData = new FormData();
        formData.append('image', croppedImageData, fileName);
        formData.append('userId', userId); // Add userId to the form data

        // Send the request
        axios
            .post('https://nrdruhs.online/uploadavatarnew', formData)
            .then((response) => {
                console.log('Cropped image uploaded successfully:', response.data);
                // Show success message using SweetAlert2
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Your Profile Avatar Image Updated Successfully',
                });
            })
            .catch((error) => {
                console.error('Error uploading cropped image:', error);
            });
    };


    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    useEffect(() => {
        console.log(preview);
    }, [preview]);


    const handleMyProfile = () => {

        navigate(-1); // Navigate back to the previous page
    };


    return (

        <div> <UttamKalakarHeader />

            <div className="container" style={{ paddingTop: 45 }}>
                <div style={{ textAlign: 'right' }}>


                    <button className="btn btn-warning" onClick={handleMyProfile}>
                        <strong>Back to My Profile</strong>
                    </button>



                </div>

                <h2 className="text-center mb-4">Update Your Profile Image</h2>

            </div>

            <div className="avatar-container" style={{ marginTop: 120 }}>

                <div className="left-container" style={{ textAlign: 'center' }}>
                    <input type="file" onChange={handleImageChange} style={{ paddingBottom: 15, display: 'block', margin: '0 auto' }} />
                    {src && (
                        <>
                            <AvatarEditor
                                ref={editorRef}
                                image={src}
                                width={200} /* Set the round shape width */
                                height={200} /* Set the round shape height */
                                borderRadius={100} /* Half of the round shape width/height */
                                onClose={onClose}
                            />
                            <div className="button-container" style={{ textAlign: 'center' }}>
                                <button onClick={onCrop} className="button" style={{ verticalAlign: 'middle' }}>
                                    Crop Image
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <div className="right-container">
                    {preview && (
                        <>
                            <strong>Cropped Image Preview:</strong><br /><br />
                            <img
                                src={preview}
                                alt="Preview"
                                style={{
                                    borderRadius: '50%',
                                    width: '100px', // Set the desired width
                                    height: '100px', // Set the desired height
                                    display: 'block',
                                    margin: '0 auto', // Center align the image
                                }}
                            />
                        </>
                    )}
                    <div className="button-container">
                        {preview ? (
                            <button onClick={saveCroppedImage} className="button">
                                Save Cropped Image
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div style={{ padding: 60 }}>
            </div>

        </div>



    );
}

export default UploadAvatar;
