import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './ListArtists.css';
import { FaChalkboardTeacher, FaUserCheck, FaClipboardCheck, FaComments } from 'react-icons/fa';

function DashboardCards() {
    const [totalArtist, setTotalArtist] = useState(0);
    const [activatedArtists, setActivatedArtists] = useState(0);
    const [deactivatedArtists, setDeactivatedArtists] = useState(0);
    const [withdrawalrCount, setWithdrawalrCount] = useState(0);

    useEffect(() => {
        // Fetch the total number of artists from the usersnew table
        const fetchTotalArtist = async () => {
            try {
                const response = await axios.get('https://nrdruhs.online/registeredartistscount');
                console.log(response.data); // Log the response data
                setTotalArtist(response.data.totalArtist);
            } catch (error) {
                console.error('Error fetching total artists:', error);
            }
        };


        // Fetch the number of activated artists (activated field/column value = 1)
        const fetchActivatedArtists = async () => {
            try {
                const response = await axios.get('https://nrdruhs.online/activatedartistscount');
                setActivatedArtists(response.data.activatedArtistsCount);
            } catch (error) {
                console.error('Error fetching activated artists:', error);
            }
        };

        // // Fetch the number of deactivated artists (activated field/column value = 0)
        // const fetchDeactivatedArtists = async () => {
        //     try {
        //         const response = await axios.get('https://nrdruhs.online/deactivatedartistscount');
        //         setDeactivatedArtists(response.data.deactivatedArtistsCount);
        //     } catch (error) {
        //         console.error('Error fetching deactivated artists:', error);
        //     }
        // };

        // Fetch the number of subscribers (role field/column contains 'Subscriber')
        const fetchWithdrawalrCount = async () => {
            try {
                const response = await axios.get('https://nrdruhs.online/withdrawalrcount');
                setWithdrawalrCount(response.data.withdrawalrCount);
            } catch (error) {
                console.error('Error fetching subscribers count:', error);
            }
        };

        fetchTotalArtist();
        fetchActivatedArtists();
        // fetchDeactivatedArtists();
        fetchWithdrawalrCount();
    }, []);

    return (
        <div className="row" style={{ paddingLeft: 72, paddingRight: 72, paddingTop: 27 }}>
            <div className="col-md-4 col-xl-3" style={{ padding: 6 }}>
                <div className="card bg-primary text-white" data-aos="fade-up" data-aos-duration="1800" style={{ padding: 12, height: 180 }}>
                    <div className="card-block">
                        <h6 className="m-b-20" style={{ paddingBottom: 6, paddingTop: 15 }}>Registered Author</h6>
                        <h2 className="text-right" style={{ textAlign: 'right' }}>
                            <FaUserCheck />
                            <span style={{ paddingLeft: 21 }}>{totalArtist}</span>
                        </h2>
                        <div style={{ textAlign: 'center', paddingTop: 15 }}>
                            <button type="button" className="btn btn-secondary btn-block">Learn More</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ padding: 3 }}>
                <div className="card bg-success text-white" data-aos="fade-down" data-aos-duration="1800" style={{ padding: 12, height: 180 }}>
                    <div className="card-block">
                        <h6 className="m-b-20" style={{ paddingBottom: 6, paddingTop: 15 }}>Activated Authors</h6>
                        <h2 className="text-right" style={{ textAlign: 'right' }}>
                            <FaChalkboardTeacher />
                            <span style={{ paddingLeft: 21 }}>{activatedArtists}</span>
                        </h2>
                        <div style={{ textAlign: 'center', paddingTop: 15 }}>
                            <button type="button" className="btn btn-secondary btn-block">Learn More</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ padding: 3 }}>
                <div className="card bg-danger text-white" data-aos="fade-up" data-aos-duration="1800" style={{ padding: 12, height: 180 }}>
                    <div className="card-block">
                        <h6 className="m-b-20" style={{ paddingBottom: 6, paddingTop: 15 }}>Published Articles</h6>
                        <h2 className="text-right" style={{ textAlign: 'right' }}>
                            <FaClipboardCheck />
                            <span style={{ paddingLeft: 21 }}>{deactivatedArtists}</span>
                        </h2>
                        <div style={{ textAlign: 'center', paddingTop: 15 }}>
                            <button type="button" className="btn btn-secondary btn-block">Learn More</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-xl-3" style={{ padding: 3 }}>
                <div className="card bg-info text-white" data-aos="fade-down" data-aos-duration="1800" style={{ padding: 12, height: 180 }}>
                    <div className="card-block">
                        <h6 className="m-b-20" style={{ paddingBottom: 6, paddingTop: 15 }}>Withdrawal Request</h6>
                        <h2 className="text-right" style={{ textAlign: 'right' }}>
                            <FaComments />
                            <span style={{ paddingLeft: 21 }}>{withdrawalrCount}</span>
                        </h2>
                        <div style={{ textAlign: 'center', paddingTop: 15 }}>
                            <button type="button" className="btn btn-secondary btn-block">Learn More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardCards;
