import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://nrdruhs.online/api/forgot-password', { email });
            const { message, success } = response.data;

            if (success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Reset Link Sent',
                    text: message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error Sending Reset Link',
                    text: message,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error Sending Reset Link',
                text: 'Please check the email and try again.',
            });
        }
    };

    return (
        <div className="container h-100" style={{ paddingTop: 60 }}>
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-md-6">
                    <h2 className="my-4">Reset Password</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label style={{ fontWeight: 'bold' }}>Your Registered Email ID</label>
                            <input
                                type="email"
                                className="form-control"
                                style={{ width: '100%' }}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">
                            Send Reset Link
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
