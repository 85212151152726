import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './UpdateManuScriptForm.css';

import { useNavigate } from 'react-router-dom';

import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';
import UpdateAuthors from './UpdateAuthors'; // Import your component here



const UpdateManuScriptForm = () => {
    const [formValues, setFormValues] = useState({
        useremail: sessionStorage.getItem('loggedInUser') || '',
        articletype: '',
        title: '',
        runningtitle: '',
        abstract: '',
        wordcount: '',
        images: '',
        tables: '',
        pages: '',
        keywords: '',
        userId: '' // Add userId here
    });

    useEffect(() => {
        // Fetch user email and user ID from sessionStorage
        const loggedInUser = sessionStorage.getItem('loggedInUser');
        const loggedInUserId = sessionStorage.getItem('userId');

        if (loggedInUser && loggedInUserId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                useremail: loggedInUser,
                userId: loggedInUserId // Set userId here
            }));
        }

    }, []);



    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check if the field is one of the fields that require numeric input validation
        if (name === 'wordcount' || name === 'images' || name === 'tables' || name === 'pages') {
            // Check if the value is not numeric
            if (!/^\d+$/.test(value)) {
                // Display alert for non-numeric input
                alert('Please enter only numeric digits for this field.');
                // Optionally, clear the field or handle the error in a different way
                return;
            }
        }

        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };


    const handleSave = async () => {
        try {
            const response = await axios.post('https://nrdruhs.online/updatemanuscriptform', formValues);
            console.log('Form data saved:', response.data);

            // Display success message
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Manuscript data updated successfully',
            }).then(() => {
                // Navigate to the UpdateManuScriptForm page
                navigate('/updateauthors');
            });

        } catch (error) {
            console.error('Error saving form data:', error);

            // Display error message
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while updating manuscript data',
            });
        }
    };

    useEffect(() => {
        const checkScrollAndScrollToTop = () => {
            setTimeout(() => {
                // Check if the user has scrolled down
                if (window.scrollY > 0) {
                    // If scrolled down, scroll to top
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }, 1000); // Wait for 1 second before checking scroll position
        };

        checkScrollAndScrollToTop();

        // Cleanup function to prevent memory leaks
        return () => clearTimeout(checkScrollAndScrollToTop);
    }, []);


    return (
        <div>

            <UttamKalakarHeader />

            <div className="container mt-5" style={{ marginTop: 30 }}>
                <h3 className="mt-2" style={{ textAlign: 'center', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, fontWeight: 'bold', backgroundColor: '#ff2056', color: 'white' }}>Step 2</h3>
                <br />
                <h2 style={{ textAlign: 'center', marginBottom: 30, fontWeight: 'bold', fontSize: 24 }}>UPDATE MANUSCRIPT FORM</h2>
                <form className="col-md-8 mx-auto">
                    <div className="row" >
                        <div className="col-md-12">
                            <table className="table table-primary">
                                <tbody >
                                    <tr>
                                        <td style={{ width: '70%' }}>
                                            <strong>
                                                <label htmlFor="approved" className="form-label">
                                                    The manuscript is seen, reviewed, and approved by all contributing authors.
                                                </label>
                                            </strong>
                                        </td>
                                        <td style={{ width: '30%' }}>
                                            <select
                                                className="form-control"
                                                id="approved"
                                                name="approved"
                                                value={formValues.approved}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <label htmlFor="separatefiles" className="form-label">
                                                    As the submitting author of the manuscript, I have prepared two separate files for submission.
                                                </label>
                                            </strong>
                                        </td>
                                        <td>
                                            <select
                                                className="form-control"
                                                id="separatefiles"
                                                name="separatefiles"
                                                value={formValues.separatefiles}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <label htmlFor="titlerunningtitle" className="form-label">
                                                    The title page is included a title and running title.
                                                </label>
                                            </strong>
                                        </td>
                                        <td>
                                            <select
                                                className="form-control"
                                                id="titlerunningtitle"
                                                name="titlerunningtitle"
                                                value={formValues.titlerunningtitle}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <label htmlFor="correctorder" className="form-label">
                                                    The name and affiliations of all the authors are mentioned in title page file in the correct order.
                                                </label>
                                            </strong>
                                        </td>
                                        <td>
                                            <select
                                                className="form-control"
                                                id="correctorder"
                                                name="correctorder"
                                                value={formValues.correctorder}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>
                                                <label htmlFor="submissionplateform" className="form-label">
                                                    As the submitting author of the manuscript, I will add all authors' name with their correct affiliations and emails to the submission platform.
                                                </label>
                                            </strong>
                                        </td>
                                        <td>
                                            <select
                                                className="form-control"
                                                id="submissionplateform"
                                                name="submissionplateform"
                                                value={formValues.submissionplateform}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="mb-3">
                        <strong>
                            <label htmlFor="articletype" className="form-label">
                                Article Type
                            </label>
                        </strong>
                        <input
                            type="text"
                            className="form-control"
                            id="articletype"
                            name="articletype"
                            value={formValues.articletype}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <strong>
                            <label htmlFor="title" className="form-label">
                                Title
                            </label>
                        </strong>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={formValues.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <strong>
                            <label htmlFor="runningtitle" className="form-label">
                                Running Title
                            </label>
                        </strong>
                        <input
                            type="text"
                            className="form-control"
                            id="runningtitle"
                            name="runningtitle"
                            value={formValues.runningtitle}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <strong>
                            <label htmlFor="abstract" className="form-label">
                                Abstract
                            </label>
                        </strong>
                        <textarea
                            className="form-control"
                            id="abstract"
                            name="abstract"
                            rows="4"
                            value={formValues.abstract}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <div className="row g-3">
                        <div className="col-md-3">
                            <strong>
                                <label htmlFor="wordcount" className="form-label">
                                    Word Count
                                </label>
                            </strong>
                            <input
                                type="text"
                                className="form-control"
                                id="wordcount"
                                name="wordcount"
                                value={formValues.wordcount}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <strong>
                                <label htmlFor="images" className="form-label">
                                    Images
                                </label>
                            </strong>
                            <input
                                type="text"
                                className="form-control"
                                id="images"
                                name="images"
                                value={formValues.images}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <strong>
                                <label htmlFor="tables" className="form-label">
                                    Tables
                                </label>
                            </strong>
                            <input
                                type="text"
                                className="form-control"
                                id="tables"
                                name="tables"
                                value={formValues.tables}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <strong>
                                <label htmlFor="pages" className="form-label">
                                    Pages
                                </label>
                            </strong>
                            <input
                                type="text"
                                className="form-control"
                                id="pages"
                                name="pages"
                                value={formValues.pages}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <strong>
                            <label htmlFor="keywords" className="form-label">
                                Keywords
                            </label>
                        </strong>
                        <input
                            type="text"
                            className="form-control"
                            id="keywords"
                            name="keywords"
                            value={formValues.keywords}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3" style={{ textAlign: 'center', padding: 30 }}>
                        <button type="button" className="btn btn-primary" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </form>

            </div>


            <UttamKalakarFooter />

        </div>
    )
}

export default UpdateManuScriptForm