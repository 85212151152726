import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FaUser, FaUpload, FaComments, FaEnvelope, FaSignOutAlt, FaLaptop, FaList, FaMarker, FaAward, FaCheck, FaGlobe, FaPlus } from 'react-icons/fa';
import './MainLayout.css';
import axios from 'axios';
import { NavDropdown } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logoImage from './NRDRUHSLogo.png'; // Import the logo image

const MainLayout = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [userRole, setUserRole] = useState(sessionStorage.getItem('userRole') || ''); // Initialize userRole from sessionStorage


    const [scrolling, setScrolling] = useState(false);

    // Function to handle the scroll event
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    // Function to handle the click event of the "Scroll to Top" button
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [showMenu, setShowMenu] = useState(false);

    // Function to toggle the visibility of the navigation links
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };


    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('token');

        if (!isAuthenticated) {
            navigate('/');
        } else {
            axios
                .get('https://nrdruhs.online/userdashboard', {
                    headers: {
                        Authorization: `Bearer ${isAuthenticated}`,
                    },
                })
                .then((res) => {
                    setUser(res.data);
                    console.log('User data:', res.data);

                    // Check if 'role' field exists in the response data
                    if (res.data && res.data.role) {
                        const userRoleFromData = res.data.role;
                        console.log('User role:', userRoleFromData);

                        // Store user's role in sessionStorage
                        sessionStorage.setItem('userRole', userRoleFromData);

                        // Update userRole with the user's role
                        setUserRole(userRoleFromData);
                    } else {
                        // Handle the case where 'role' is missing in the response
                        console.error('Role data is missing in the response.');
                        // You can choose to handle this case differently, e.g., set a default role or show an error message.
                    }
                })
                .catch((err) => {
                    console.error('Failed to fetch user data:', err);
                });
        }
    }, [navigate]);



    const handleLogout = () => {
        fetch('https://nrdruhs.online/logout', {
            method: 'POST',
            credentials: 'include',
        })
            .then((response) => {
                console.log('Logout response:', response);
                if (response.ok) {
                    console.log('Logout successful');
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('loggedInUser');
                    sessionStorage.removeItem('userId');
                    sessionStorage.removeItem('userRole');
                    navigate('/');
                    window.location.reload(); // Refresh the page
                } else {
                    console.log('Logout failed');
                }
            })
            .catch((error) => {
                console.error('Logout error:', error);
            });
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="custom-container">
            <div>
                <nav className={`navBar ${scrolling ? 'scrolling' : ''}`} data-aos="fade-down" data-aos-duration="1200">

                    {/* Logo Image */}
                    <img src={logoImage} alt="Logo" className="logo" />

                    {/* Hamburger Menu Button */}
                    <div className="menuButton" onClick={toggleMenu}>
                        <div className={`menuIcon ${showMenu ? 'open' : ''}`} />
                    </div>

                    {/* Navigation Links */}
                    <ul className={`navLinks ${showMenu ? 'show' : ''}`}>
                        <li>
                            <Link to="/" className="link">Home</Link>
                        </li>


                        <li>
                            <Link to="" className="link">About NRD</Link>
                        </li>
                        <li>
                            <Link to="" className="link">Editorial Board</Link>
                        </li>
                        <li>
                            <Link to="" className="link">Current Issues</Link>
                        </li>
                        <li>
                            <Link to="" className="link">Other Issues</Link>
                        </li>

                    </ul>

                    <div className="avatar" data-aos="fade-down" data-aos-duration="1200" style={{ position: 'relative', zIndex: 2 }}>
                        {user && user.image && (
                            <img
                                src={`https://nrdruhs.online/uploads/${user.image}`}
                                alt="User Avatar"
                                className="navbarImgList"
                            />
                        )}
                        {user && user.image && (
                            <NavDropdown title={`Hi, ${user.name}`} id="basic-nav-dropdown" style={{ zIndex: 1000 }}>
                                <NavDropdown.Item href="userprofile"><FaUser style={{ marginRight: 6, color: 'DodgerBlue' }} /> My Profile</NavDropdown.Item>
                                {userRole !== 'Reviewer' && (
                                    <NavDropdown.Item href="manuscriptsteps"><FaList style={{ marginRight: 6, color: 'DodgerBlue' }} /> Steps - Uploading Article</NavDropdown.Item>
                                )}
                                <NavDropdown.Item href="inboxmessages"><FaComments style={{ marginRight: 6, color: 'DodgerBlue' }} /> My Messages</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="messageuser"><FaEnvelope style={{ marginRight: 6, color: 'DodgerBlue' }} /> Send Messages</NavDropdown.Item>

                                {/* Admin-specific menu items */}
                                {userRole === 'Admin' && (
                                    <>
                                        <NavDropdown.Item href="assignauthorreview"><FaMarker style={{ marginRight: 6, color: 'DodgerBlue' }} /> Assign Author - Review </NavDropdown.Item>
                                        <NavDropdown.Item href="reviewcheckedarticle"><FaCheck style={{ marginRight: 6, color: 'DodgerBlue' }} /> Review Checked Article </NavDropdown.Item>
                                        <NavDropdown.Item href="issuecertreview"><FaAward style={{ marginRight: 6, color: 'DodgerBlue' }} /> Issue Certificate - Review </NavDropdown.Item>
                                        <NavDropdown.Item href="admindashboard"><FaLaptop style={{ marginRight: 6, color: 'DodgerBlue' }} /> My Dashboard</NavDropdown.Item>
                                        <NavDropdown.Item href="nrdarticlespublish"><FaGlobe style={{ marginRight: 6, color: 'DodgerBlue' }} /> Publish Articles</NavDropdown.Item>
                                        <NavDropdown.Item href="uploadarticleassign"><FaUpload style={{ marginRight: 6, color: 'DodgerBlue' }} /> Upload Article - Assign</NavDropdown.Item>
                                        <NavDropdown.Item href="addarticleissue"><FaPlus style={{ marginRight: 6, color: 'DodgerBlue' }} /> Add New Volume Issue</NavDropdown.Item>
                                    </>
                                )}

                                {/* Review Author-specific menu items */}
                                {userRole === 'Reviewer' && (
                                    <>
                                        <NavDropdown.Item href="myreview"><FaMarker style={{ marginRight: 6, color: 'DodgerBlue' }} /> My Assigned Review </NavDropdown.Item>
                                        <NavDropdown.Item href="reviewupload"><FaUpload style={{ marginRight: 6, color: 'DodgerBlue' }} /> Review Upload</NavDropdown.Item>
                                        <NavDropdown.Item href="mycertificatereview"><FaAward style={{ marginRight: 6, color: 'DodgerBlue' }} /> My Certificate - Review </NavDropdown.Item>
                                    </>
                                )}

                                {/* Common divider and logout option */}
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}><FaSignOutAlt style={{ marginRight: 6, color: 'DodgerBlue' }} /> Logout</NavDropdown.Item>
                            </NavDropdown>
                        )}
                    </div>




                </nav>
            </div>


            <div className="main-content" style={{ padding: 0, backgroundColor: '#efefef' }}>
                <Outlet />
            </div>


            {/* Scroll to Top Button */}
            <button
                className={`scroll-to-top-btn ${scrolling ? 'show' : ''}`}
                onClick={handleScrollToTop}
                title="Scroll to Top"
            >
                <i className="fas fa-arrow-up"></i>
            </button>


        </div>

    );
};

export default MainLayout;
