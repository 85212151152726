import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ArticleWithdrawal = () => {
    const [titlesList, setTitlesList] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');

    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const loggedInUser = sessionStorage.getItem('loggedInUser'); // Get the logged-in user's email from sessionStorage
                console.log('loggedInUser:', loggedInUser); // Log the loggedInUser to ensure it's retrieved
                const response = await axios.get(`https://nrdruhs.online/api/manuscriptTitleswithd?loggedInUser=${loggedInUser}`); // Pass the loggedInUser in the query parameter
                setTitlesList(response.data.titles);
            } catch (error) {
                console.error('Error fetching titles:', error.message);
            }
        };

        fetchTitles();
    }, []);

    const handleWithdrawalRequest = async () => {
        try {
            const selectedTitleObject = titlesList.find((titleObject) => titleObject.title === selectedTitle);
            if (selectedTitleObject) {
                await axios.put(`https://nrdruhs.online/articlewithdrawal/${selectedTitleObject.articleid}`, {
                    withdrawalRequest: 'Yes'
                });

                // Send email to server endpoint
                await axios.post('https://nrdruhs.online/sendwithdrawalrequest', { title: selectedTitle });

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Withdrawal request sent successfully',
                });
            }
        } catch (error) {
            console.error('Error sending withdrawal request:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while sending the withdrawal request',
            });
        }
    };

    return (
        <div>
            <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>Withdrawal Request</h3>

            <div className="col-sm-12">
                <select
                    className="form-select form-select-lg mb-3 mb-lg-0"
                    value={selectedTitle}
                    onChange={(e) => setSelectedTitle(e.target.value)}
                    style={{ fontSize: '16px', fontWeight: 'bold' }} // Apply font size directly to the select element
                >
                    <option value="" style={{ fontSize: '18px', fontWeight: 'bold' }}>Select a title</option>
                    {titlesList.map((titleObject) => {
                        // Check if title length exceeds 66 characters
                        const displayTitle = titleObject.title.length > 66 ?
                            `${titleObject.title.substring(0, 66)}...` :
                            titleObject.title;

                        return (
                            <option
                                key={titleObject.title}
                                value={titleObject.title}
                                style={{ fontSize: '18px' }} // Inline CSS for font size
                            >
                                {displayTitle} - {titleObject.articleid}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className="text-center" style={{ marginTop: 30, marginBottom: 60 }}>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleWithdrawalRequest}
                    style={{ padding: 15, fontWeight: 'bold' }}
                >
                    Send Request
                </button>
            </div>
        </div>
    );
};

export default ArticleWithdrawal;
