import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import './MessageUser.css';
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';

const MessageUser = () => {
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);


    const sendMessage = async () => {
        try {
            const currentDate = new Date().toISOString();
            const loggedInUser = sessionStorage.getItem('loggedInUser');

            const data = {
                messagefrom: loggedInUser,
                messageto: selectedUser.value,
                messagedate: currentDate,
                message: message
            };

            console.log('Sending data:', data); // Add this line for debugging

            const response = await axios.post('https://nrdruhs.online/api/sendmessage', data);
            console.log('Response:', response); // Log the response for debugging

            // After storing the message, send an email
            sendEmail(loggedInUser, selectedUser.value, message);

            setMessage('');

            Swal.fire('Success', 'Message sent successfully!', 'success');
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response.data);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };




    const sendEmail = async (from, to, message) => {
        try {
            const defaultSubject = 'Message - NRD RUHS Articles Portal';
            const defaultMessage = `Hello ${to.toUpperCase()},\n\nYou have received a message from NRD RUHS Articles Portal.\n\nPlease login to check your message on https://nrdruhs.org\n\nThanks,\nNRD RUHS Articles Portal`;




            // Fetch the email for the selected user
            const response = await axios.get('https://nrdruhs.online/api/getuseremail', {
                params: {
                    username: to,
                },
            });

            const { email } = response.data;

            const emailData = {
                to: email, // Use the selected user's email
                subject: defaultSubject,
                text: defaultMessage,
            };

            await axios.post('https://nrdruhs.online/api/sendemail', emailData);
            console.log('Email sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };


    useEffect(() => {
        axios
            .get('https://nrdruhs.online/listusers')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const options = data.map((user) => ({
        value: user.name,
        label: (
            <div className="dropdown-option">
                <img src={`https://nrdruhs.online/uploads/` + user.image} alt="userImg" className="userImgList" />
                <span className="dropdown-option-name" style={{ paddingLeft: 6 }}>{user.name}</span>
            </div>
        ),
    }));

    return (
        <div style={{ background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
            <UttamKalakarHeader />
            <div className="container" style={{ marginTop: 30, marginBottom: 90 }}>
                <div className="my-4" data-aos="fade-left" data-aos-duration="1200">
                    <h2 style={{ textAlign: 'center' }}>Send Message</h2>
                </div>
                <div className="my-4 input-container" data-aos="fade-up" data-aos-duration="1800" style={{ background: '#B3E7FF', padding: 30, border: 1, borderRadius: 12 }}>
                    <div className="form-group">
                        <label htmlFor="user-select">Select User</label>
                        <Select
                            options={options}
                            value={selectedUser}
                            onChange={(selectedOption) => setSelectedUser(selectedOption)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message-input">Message</label>
                        <textarea
                            id="message-input"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ height: '150px' }}
                            className="form-control"
                        ></textarea>
                    </div>
                    <div className="form-group" style={{ paddingTop: 12 }}>
                        <button type="button" className="btn btn-primary" onClick={sendMessage}>
                            Send Message
                        </button>
                    </div>
                </div>
            </div>
            <UttamKalakarFooter />
        </div>
    );
};

export default MessageUser;
