import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ArticleStatus = () => {
    const [articlesStatus, setArticlesStatus] = useState([]);

    useEffect(() => {
        const fetchArticlesStatus = async () => {
            try {
                const userId = sessionStorage.getItem('userId');
                const response = await axios.get(`https://nrdruhs.online/articlestatus?userId=${userId}`);
                setArticlesStatus(response.data.articles);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticlesStatus();
    }, []);

    return (
        <div>
            <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>List of Articles</h3>
            <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th style={{ backgroundColor: '#007bff', color: 'white', width: '20%' }}>Article Id</th>
                        <th style={{ backgroundColor: '#007bff', color: 'white', width: '80%' }}>Title</th>
                    </tr>
                </thead>
                <tbody>
                    {articlesStatus.map((article, index) => (
                        <tr key={index}>
                            <td>{article.articleid}</td>
                            <td>{article.title}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ArticleStatus;
