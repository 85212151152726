import React from 'react';
import './ListArtists.css';
import UttamKalakarHeader from './UttamKalakarHeader';
import MultiAuthorCard from './MultiAuthorCard';

import DashboardCards from './DashboardCards';
import MultiAuthorCardIndia from './MultiAuthorCardIndia';
import ListAuthorDashboard from './ListAuthorDashboard';
import UttamKalakarFooter from './UttamKalakarFooter';
import ListReviewAuthorCards from './ListReviewAuthorCards';


function AdminDashboard() {


    return (

        <div style={{ overflowX: 'hidden' }}>
            <UttamKalakarHeader />

            <div style={{ textAlign: 'center', paddingTop: 60 }}>
                <h3>
                    ADMIN DASHBOARD
                </h3>

            </ div>

            <DashboardCards />



            <div className="row" style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 3, paddingBottom: 3 }}>

                <MultiAuthorCardIndia />

                <ListReviewAuthorCards />

                <MultiAuthorCard />
            </div>
            <div className="row" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 30, marginTop: -30 }}>

                <ListAuthorDashboard />
            </div>



            <div className="row" style={{ padding: 60 }}>

            </div>

            <UttamKalakarFooter />
        </div >

    );
}

export default AdminDashboard;
