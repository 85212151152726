import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ArticlePublished = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const userId = sessionStorage.getItem('userId'); // Get the user ID from sessionStorage
                console.log('userId:', userId); // Log the userId to ensure it's retrieved
                const response = await axios.get(`https://nrdruhs.online/articlepublished?userId=${userId}`); // Pass the user ID in the query parameter
                setArticles(response.data.articles);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <div>
            <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>List of Published Articles</h3>
            <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th style={{ backgroundColor: '#007bff', color: 'white', width: '70%' }}>Title</th>
                        <th style={{ backgroundColor: '#007bff', color: 'white', width: '30%', textAlign: 'center' }}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {articles.map((article, index) => (
                        <tr key={index} style={{ verticalAlign: 'middle' }}>
                            <td style={{ fontWeight: 'bold' }}>{article.title}</td>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Published</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ArticlePublished;
