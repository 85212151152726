import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card, Dropdown, Button } from 'react-bootstrap'; // Assuming you're using Bootstrap for the card, dropdown, and button components
import UttamKalakarFooter from './UttamKalakarFooter';

const AsignAuthorReview = () => {
    const [titlesList, setTitlesList] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState('');


    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const loggedInUser = sessionStorage.getItem('loggedInUser');
                console.log('loggedInUser:', loggedInUser);
                const response = await axios.get(`https://nrdruhs.online/api/manuscriptTitlesAssignR`);
                setTitlesList(response.data.titles);
            } catch (error) {
                console.error('Error fetching titles:', error.message);
            }
        };

        const fetchAuthors = async () => {
            try {
                const response = await axios.get(`https://nrdruhs.online/api/authors?role=Reviewer`);
                // Assuming the backend response structure is { authors: [{ name: 'Author Name', email: 'author@email.com' }, ...] }
                const authorsWithEmail = response.data.authors.filter(author => author.email); // Filter out authors without email
                setAuthors(authorsWithEmail);
            } catch (error) {
                console.error('Error fetching authors:', error.message);
            }
        };


        fetchTitles();
        fetchAuthors();
    }, []);

    const handleAssignAuthor = async () => {
        try {
            // Make sure a title and author are selected
            if (!selectedTitle || !selectedAuthor) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select a title and an author',
                });
                return;
            }

            // Log the selected author object to check its structure
            console.log('Selected Author:', selectedAuthor);

            // Log the email of the selected author to check its value
            console.log('Selected Author Email:', selectedAuthor.email);

            // Send a request to update the manuscript with the selected author
            await axios.put(`https://nrdruhs.online/api/assignAuthorToManuscript`, {
                title: selectedTitle,
                author: selectedAuthor,
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Author assigned to the manuscript successfully',
            });

            // Send email to review author with the selected author's email
            await sendEmailToReviewAuthor(selectedAuthor.email); // Assuming the author object has an 'email' property
        } catch (error) {
            console.error('Error assigning author:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while assigning the author or sending the email',
            });
        }
    };



    const sendEmailToReviewAuthor = () => {
        const user = { name: 'Bharat Sankhala' }; // Placeholder for user object
        const message = `Hello Reviewer,\n\nAdmin ${user.name} has assigned you an Article to review. \n\nThanks Web Administrator`;

        // Get the selected author object from the authors state based on the selectedAuthor name
        const selectedAuthorObject = authors.find(author => author.name === selectedAuthor);

        // Check if the selectedAuthorObject exists and has an email property
        if (selectedAuthorObject && selectedAuthorObject.email) {
            const email = selectedAuthorObject.email;
            console.log('Email to send:', email); // Log the email to check its value

            axios.post('https://nrdruhs.online/sendemailtoreviewauthor', {
                email: email,
                message: message
            })
                .then(response => {
                    // Handle success response
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Email sent to Reviewer successfully.'
                    });
                })
                .catch(error => {
                    // Handle error response
                    console.error('Error sending email to reviewer:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Failed to send email to reviewer. Please try again later.'
                    });
                });
        } else {
            console.error('Selected author email not found or invalid');
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Selected author email not found or invalid. Please try again later.'
            });
        }
    };









    return (
        <div>
            <div className="main-content">
                <img
                    src="/assets/NRDRUHSHomeBanner.jpg"
                    alt="Background"
                    className="header-image"
                    style={{ width: '100%', height: '60vh', objectFit: 'cover' }}
                />
            </div>

            <div style={{ marginTop: 90, marginBottom: 90 }}>
                <Card style={{ margin: '0 auto', maxWidth: 600, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
                    <Card.Body>
                        <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>Assign Author to Review</h3>
                        <div className="col-sm-12">
                            <select
                                className="form-select form-select-lg mb-3 mb-lg-0"
                                value={selectedTitle}
                                onChange={(e) => setSelectedTitle(e.target.value)}
                                style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                                <option value="" style={{ fontSize: '18px', fontWeight: 'bold' }}>Select a title</option>
                                {titlesList.map((titleObject) => {
                                    const displayTitle = titleObject.title.length > 66 ?
                                        `${titleObject.title.substring(0, 66)}...` :
                                        titleObject.title;
                                    return (
                                        <option
                                            key={titleObject.title}
                                            value={titleObject.title}
                                            style={{ fontSize: '18px' }}
                                        >
                                            {displayTitle} - {titleObject.articleid}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                    </Card.Body>
                </Card>

                <div style={{ textAlign: 'center' }}>

                    <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>Select Author</h3>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-authors">
                            {selectedAuthor ? selectedAuthor : 'Select an Author'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {authors.map((author) => (
                                <Dropdown.Item
                                    key={author.id}
                                    onClick={() => setSelectedAuthor(author.name)}
                                >
                                    {author.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                </div>



                <div className="text-center mt-3">
                    <Button variant="primary" onClick={handleAssignAuthor}>
                        Assign
                    </Button>
                </div>
            </div>

            {/* Button to send email to admin */}
            <div className="text-center mt-4">
                <button className="btn btn-warning" onClick={sendEmailToReviewAuthor} style={{ fontSize: '21px', fontWeight: 'bold' }}>CONFIRM UPLOAD & SAVE</button>
            </div>

            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>





        </div>
    );
};

export default AsignAuthorReview;
