import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';
import UpdateManuScriptForm from './UpdateManuScriptForm'; // Import your component here

function ArticleUpload() {
    const [coverPage, setCoverPage] = useState(null);
    const [mainDocument, setMainDocument] = useState(null);
    const [tableDocument, setTableDocument] = useState(null);
    const [photoDocument, setPhotoDocument] = useState(null);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [uploadedMainDocuments, setUploadedMainDocuments] = useState([]);
    const [uploadedTables, setUploadedTables] = useState([]);
    const [uploadedPhotos, setUploadedPhotos] = useState([]);

    const navigate = useNavigate();



    const userId = sessionStorage.getItem('userId'); // Retrieve userId from localStorage


    const [user, setUser] = useState(null);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('token');

        if (isAuthenticated) {
            axios.get('https://nrdruhs.online/userdashboard', {
                headers: {
                    Authorization: `Bearer ${isAuthenticated}`,
                },
            })
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {
                    console.error('Failed to fetch user data:', err);
                });
        } else {
            console.error('User is not authenticated');
        }
    }, []);




    console.log(userId);
    // eslint-disable-next-line
    useEffect(() => {
        fetchUploadedDocuments();
    }, [userId]);

    const fetchUploadedDocuments = () => {
        axios
            .get(`https://nrdruhs.online/upload/displaystatus?userId=${userId}`)
            .then((response) => {
                setUploadedDocuments(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const isWordDocument = (file) => {
        const allowedExtensions = /(\.doc|\.docx)$/i;
        return allowedExtensions.test(file.name);
    };




    const handleCoverPageUpload = (event) => {
        const file = event.target.files[0];
        if (file && isWordDocument(file)) {
            setCoverPage(file);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please upload a .doc or .docx file.',
            });
            event.target.value = ''; // Clear the file input
        }
    };

    const handleCoverPageSubmit = () => {
        if (!coverPage) {
            return; // No file selected, handle validation or display an error message
        }

        const formData = new FormData();
        formData.append('coverPage', coverPage);
        formData.append('fileNamePrefix', 'coverpage');
        formData.append('userId', userId);

        axios
            .post('https://nrdruhs.online/upload/coverpage', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Cover Page File uploaded successfully.',
                }).then(() => {
                    fetchUploadedDocuments();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to upload file.',
                });
                console.error(error);
            });
    };


    // eslint-disable-next-line
    useEffect(() => {
        fetchUploadedMainDocuments();
    }, [userId]);

    const fetchUploadedMainDocuments = () => {
        axios
            .get(`https://nrdruhs.online/upload/displaystatusmd?userId=${userId}`)
            .then((response) => {
                setUploadedMainDocuments(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleMainDocumentUpload = (event) => {
        const file = event.target.files[0];
        if (file && isWordDocument(file)) {
            setMainDocument(file);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please upload a .doc or .docx file.',
            });
            event.target.value = ''; // Clear the file input
        }
    };


    const handleMainDocumentSubmit = () => {
        if (!mainDocument) {
            return; // No file selected, handle validation or display an error message
        }

        const formData = new FormData();
        formData.append('maindocument', mainDocument);
        formData.append('fileNamePrefix', 'maindocument');
        formData.append('userId', userId);

        axios
            .post('https://nrdruhs.online/upload/coverpagemaindocument', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Main Document File uploaded successfully.',
                }).then(() => {
                    fetchUploadedMainDocuments();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to upload file.',
                });
                console.error(error);
            });
    };



    // eslint-disable-next-line
    useEffect(() => {
        fetchUploadedTables();
    }, [userId]);

    const fetchUploadedTables = () => {
        axios
            .get(`https://nrdruhs.online/upload/displaystatustables?userId=${userId}`)
            .then((response) => {
                setUploadedTables(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleTableUpload = (event) => {
        const file = event.target.files[0];
        if (file && isWordDocument(file)) {
            setTableDocument(file);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please upload a .doc or .docx file.',
            });
            event.target.value = ''; // Clear the file input
        }
    };



    const handleTableSubmit = () => {
        if (!tableDocument) {
            return; // No file selected, handle validation or display an error message
        }

        const formData = new FormData();
        formData.append('tabledoc', tableDocument);
        formData.append('fileNamePrefix', 'tabledoc');
        formData.append('userId', userId);

        axios
            .post('https://nrdruhs.online/upload/coverpagetables', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Tables File uploaded successfully.',
                }).then(() => {
                    fetchUploadedTables();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to upload file.',
                });
                console.error(error);
            });
    };




    // eslint-disable-next-line
    useEffect(() => {
        fetchUploadedPhotos();
    }, [userId]);

    const fetchUploadedPhotos = () => {
        axios
            .get(`https://nrdruhs.online/upload/displaystatusphotos?userId=${userId}`)
            .then((response) => {
                setUploadedPhotos(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handlePhotoUpload = (event) => {
        const file = event.target.files[0];
        if (file && isWordDocument(file)) {
            setPhotoDocument(file);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please upload a .doc or .docx file.',
            });
            event.target.value = ''; // Clear the file input
        }
    };




    const handlePhotoSubmit = () => {
        if (!photoDocument) {
            return; // No file selected, handle validation or display an error message
        }

        const formData = new FormData();
        formData.append('photodoc', photoDocument);
        formData.append('fileNamePrefix', 'photodoc');
        formData.append('userId', userId);

        axios
            .post('https://nrdruhs.online/upload/coverpagephoto', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Photographs File uploaded successfully.',
                }).then(() => {
                    fetchUploadedPhotos();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to upload file.',
                });
                console.error(error);
            });
    };






    const handleDeleteDocument = () => {
        axios
            .post(`https://nrdruhs.online/upload/delete/${userId}`)
            .then((response) => {
                console.log(response.data);
                Swal.fire('Deleted', 'Cover Page Document deleted successfully. Thanks!', 'success').then(() => {
                    fetchUploadedDocuments();
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const handleDeleteMainDocument = () => {
        axios
            .post(`https://nrdruhs.online/upload/deletemaindocument/${userId}`)
            .then((response) => {
                console.log(response.data);
                Swal.fire('Deleted', 'Main Document deleted successfully. Thanks!', 'success').then(() => {
                    fetchUploadedMainDocuments();
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const handleDeleteTables = () => {
        axios
            .post(`https://nrdruhs.online/upload/deletetabledoc/${userId}`)
            .then((response) => {
                console.log(response.data);
                Swal.fire('Deleted', 'Table Document deleted successfully. Thanks!', 'success').then(() => {
                    fetchUploadedTables();
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleDeletePhotos = () => {
        axios
            .post(`https://nrdruhs.online/upload/deletephotodoc/${userId}`)
            .then((response) => {
                console.log(response.data);
                Swal.fire('Deleted', 'Photographs Document File deleted successfully. Thanks!', 'success').then(() => {
                    fetchUploadedPhotos();
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const sendEmailToAdmin = () => {
        // Check if all three documents are uploaded
        if (coverPage && mainDocument && tableDocument && photoDocument) {
            // Define the email content including the user's name
            const message = `Hello Admin,\n\nAuthor ${user.name} has uploaded their Article.\n\nThanks\n\nNRD RUHS`;

            // Make a POST request to send the email
            axios.post('https://nrdruhs.online/sendemailtoadmin', {
                email: 'pradeepkhemnani2018@gmail.com',
                message: message
            })
                .then(response => {
                    // Handle success response
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Uploaded and Email sent to Admin successfully.'
                    }).then(() => {
                        navigate('/updatemanuscriptform'); // Navigate to UpdateManuScriptForm
                    });
                })
                .catch(error => {
                    // Handle error response
                    console.error('Error sending email to admin:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Failed to send email to admin. Please try again later.'
                    });
                });
        } else {
            // Handle the case where any document is missing
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please upload all 4 documents before saving.'
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (

        <div>
            <UttamKalakarHeader />


            <div className="container" style={{ paddingTop: 45, paddingBottom: 45 }}>
                <h3 className="mt-2" style={{ textAlign: 'center', paddingLeft: 30, paddingRight: 30, paddingTop: 15, paddingBottom: 15, fontWeight: 'bold', backgroundColor: '#ff2056', color: 'white' }}>Step 1</h3>
                <br />
                <h3 className="mt-2" style={{ textAlign: 'center' }}>GUIDELINES FOR UPLOADING DOCUMENTS</h3>

                <div className="container mt-5">
                    <table className="table table-bordered">
                        <thead style={{ backgroundColor: '#007bff', color: '#ffffff' }}>
                            <tr>
                                <th scope="col" style={{ padding: '12px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#0099ff', color: 'white' }}>First Page File <br />(Including Cover Page)</th>
                                <th scope="col" style={{ padding: '12px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#0099ff', color: 'white' }}>Article File</th>
                                <th scope="col" style={{ padding: '12px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#0099ff', color: 'white' }}>Multimedia Files</th>
                                <th scope="col" style={{ padding: '12px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#0099ff', color: 'white' }}>Supplementary Files</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '12px', verticalAlign: 'middle', fontSize: 12 }}><i className="fa fa-check" style={{ paddingRight: 6 }}></i> The file should be Word Document.</td>
                                <td style={{ padding: '12px', verticalAlign: 'middle', fontSize: 12 }}><i className="fa fa-check" style={{ paddingRight: 6 }}></i> The file should be Word Document.</td>
                                <td style={{ padding: '12px', verticalAlign: 'middle', fontSize: 12 }}><i className="fa fa-check" style={{ paddingRight: 6 }}></i> The file should be Word Document.</td>
                                <td style={{ padding: '12px', verticalAlign: 'middle', fontSize: 12 }}><i className="fa fa-check" style={{ paddingRight: 6 }}></i> The file should be Word Document.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>




                <h3 className="mt-2" style={{ textAlign: 'center', padding: 30, fontWeight: 'bold' }}>UPLOAD DOCUMENTS</h3>





                <div className="card mt-4" style={{ backgroundColor: '#dfdfdf' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ textAlign: 'center' }}>Cover Page / TITLE</h5><br />
                        <div className="form-group">
                            <div className="custom-file">
                                <input type="file" id="coverPage" className="custom-file-input" onChange={handleCoverPageUpload} />
                                <label className="custom-file-label" htmlFor="coverPage">
                                    {coverPage ? coverPage.name : ''}
                                </label>

                            </div>
                        </div><br />
                        <button className="btn btn-primary" onClick={handleCoverPageSubmit}>
                            Upload Cover Page
                        </button>


                        {uploadedDocuments.length > 0 && (
                            <div className="mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Uploaded Cover Page / TITLE</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadedDocuments.map((document) => (
                                            <tr key={document.id}>
                                                <td>{document.fileName}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteDocument(document.id)}>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

                <div className="card mt-4" style={{ backgroundColor: '#dfdfdf' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ textAlign: 'center' }}>Main Document</h5>
                        <div className="form-group">
                            <div className="custom-file">
                                <input type="file" id="mainDocument" className="custom-file-input" onChange={handleMainDocumentUpload} />
                                <label className="custom-file-label" htmlFor="mainDocument">
                                    {mainDocument ? mainDocument.name : ''}
                                </label>

                            </div>
                        </div><br />
                        <button className="btn btn-primary" onClick={handleMainDocumentSubmit}>
                            Upload Main Document
                        </button>


                        {uploadedMainDocuments.length > 0 && (
                            <div className="mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Uploaded Main Document</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadedMainDocuments.map((maindocument) => (
                                            <tr key={maindocument.id}>
                                                <td>{maindocument.fileName}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteMainDocument(maindocument.id)}>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>



                <div className="card mt-4" style={{ backgroundColor: '#dfdfdf' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ textAlign: 'center' }}>Tables</h5>
                        <div className="form-group">
                            <div className="custom-file">
                                <input type="file" id="mainDocument" className="custom-file-input" onChange={handleTableUpload} />
                                <label className="custom-file-label" htmlFor="mainDocument">
                                    {tableDocument ? tableDocument.name : ''}
                                </label>

                            </div>
                        </div><br />
                        <button className="btn btn-primary" onClick={handleTableSubmit}>
                            Upload Tables                        </button>


                        {uploadedTables.length > 0 && (
                            <div className="mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Uploaded Tables</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadedTables.map((tabledoc) => (
                                            <tr key={tabledoc.id}>
                                                <td>{tabledoc.fileName}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteTables(tabledoc.id)}>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>


                <div className="card mt-4" style={{ backgroundColor: '#dfdfdf' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ textAlign: 'center' }}>Photographs</h5>
                        <div className="form-group">
                            <div className="custom-file">
                                <input type="file" id="mainDocument" className="custom-file-input" onChange={handlePhotoUpload} />
                                <label className="custom-file-label" htmlFor="mainDocument">
                                    {photoDocument ? photoDocument.name : ''}
                                </label>

                            </div>
                        </div><br />
                        <button className="btn btn-primary" onClick={handlePhotoSubmit}>
                            Upload Photographs
                        </button>


                        {uploadedPhotos.length > 0 && (
                            <div className="mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Uploaded Photographs</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadedPhotos.map((photodoc) => (
                                            <tr key={photodoc.id}>
                                                <td>{photodoc.fileName}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleDeletePhotos(photodoc.id)}>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>





                {/* Button to send email to admin */}
                <div className="text-center mt-4">
                    <button className="btn btn-warning" onClick={sendEmailToAdmin} style={{ fontSize: '21px', fontWeight: 'bold' }}>CONFIRM UPLOAD & SAVE</button>

                </div>


            </div>





            <UttamKalakarFooter />
        </div>
    );
}

export default ArticleUpload;
