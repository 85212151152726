import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';

function NRDArticlesPublish() {
    const [coverPage, setCoverPage] = useState(null);
    const [image, setImage] = useState(null);
    const userId = sessionStorage.getItem('userId'); // Retrieve userId from sessionStorage

    const [articleData, setArticleData] = useState({
        articleid: '',
        title: '',
        dateofpublish: ''
    });

    const handleArticleDataChange = (e) => {
        const { name, value } = e.target;
        setArticleData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCoverPageUpload = (event) => {
        const file = event.target.files[0];
        setCoverPage(file);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImage(file);
    };

    const handleUpload = () => {
        if (!coverPage || !image || !articleData.articleid || !articleData.title || !articleData.dateofpublish) {
            return; // Handle validation or display an error message
        }

        const formData = new FormData();
        formData.append('coverPage', coverPage);
        formData.append('image', image);
        formData.append('fileNamePrefix', 'NRDRUHS');
        formData.append('userId', userId);
        formData.append('articleid', articleData.articleid);
        formData.append('title', articleData.title);
        formData.append('dateofpublish', articleData.dateofpublish);

        axios
            .post('https://nrdruhs.online/upload/nrdmainarticlepublish', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Files uploaded successfully.',
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to upload files.',
                });
                console.error(error);
            });
    };

    return (
        <div>
            <UttamKalakarHeader />
            <div className="main-content" style={{ padding: 30, paddingBottom: 120, marginTop: -120, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-4 col-md-6 col-sm-8 col-10">
                        <div className="card p-4 shadow" data-aos="fade-up" data-aos-duration="1200" style={{ background: '#B3E7FF' }}>
                            <h3 className="mb-4 text-center" style={{ fontSize: 21, fontWeight: 'bold' }}>UPLOAD MAIN ARTICLE</h3>
                            <form>
                                <div className="form-group" style={{ paddingBottom: 12 }}>
                                    <label htmlFor="articleid" style={{ fontWeight: 'bold' }}>Article ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="articleid"
                                        name="articleid"
                                        value={articleData.articleid}
                                        onChange={handleArticleDataChange}
                                    />
                                </div>
                                <div className="form-group" style={{ paddingBottom: 12 }}>
                                    <label htmlFor="title" style={{ fontWeight: 'bold' }}>Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={articleData.title}
                                        onChange={handleArticleDataChange}
                                    />
                                </div>
                                <div className="form-group" style={{ paddingBottom: 12 }}>
                                    <label htmlFor="dateofpublish" style={{ fontWeight: 'bold' }}>Date of Publish</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="dateofpublish"
                                        name="dateofpublish"
                                        value={articleData.dateofpublish}
                                        onChange={handleArticleDataChange}
                                    />
                                </div>
                                <div className="form-group" style={{ paddingBottom: 12 }}>
                                    <label htmlFor="coverPage" style={{ fontWeight: 'bold' }}>Upload Document</label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="coverPage"
                                        name="coverPage"
                                        accept=".pdf,.doc,.docx"
                                        onChange={handleCoverPageUpload}
                                    />
                                </div>
                                <div className="form-group" style={{ paddingBottom: 12 }}>
                                    <label htmlFor="image" style={{ fontWeight: 'bold' }}>Upload Image</label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        id="image"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                                <div className="text-center" style={{ padding: 30 }}>
                                    <button type="button" className="btn btn-primary" onClick={handleUpload}>
                                        Upload Article
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <UttamKalakarFooter />
        </div>
    );
}

export default NRDArticlesPublish;
