import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './ListArtists.css';
import { useNavigate } from 'react-router-dom';

function ListAuthorDashboard() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalArtist, setTotalArtist] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/listusers')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        const fetchTotalArtist = async () => {
            try {
                const response = await fetch('https://nrdruhs.online/usersnewcount');
                const data = await response.json();
                setTotalArtist(data.totalArtist);
            } catch (error) {
                console.error('Error fetching total staff:', error);
            }
        };
        fetchTotalArtist();
    }, []);

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/usersnewsearch', {
                params: { search: searchTerm },
            })
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, [searchTerm]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <nav aria-label="...">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            tabIndex="-1"
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
                        <li className={`page-item ${currentPage === pageNumber ? 'active' : ''}`} key={pageNumber}>
                            <button className="page-link" onClick={() => paginate(pageNumber)}>
                                {pageNumber}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === pageNumbers.length}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        );
    };

    const downloadList = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });

        // Make a request to download the artist list in Excel format with the formatted date in the filename
        axios
            .get('https://nrdruhs.online/downloadartistlist', { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `List_Artists_${formattedDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error('Error downloading the list:', error);
                alert('Error downloading the list.');
            });
    };

    return (
        <div className="container-fluid px-0">
            <div className="px-5 py-3">
                <div className='d-flex justify-content-between align-items-center mt-2'>
                    <h3 style={{ paddingBottom: 15 }}>
                        <strong></strong>
                    </h3>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='me-3'>
                        <label className='form-label me-1' style={{ fontWeight: 'bold' }}>Search Name</label>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Search by name'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mt-3" data-aos="fade-up" data-aos-duration="1800">
                    <table className="table w-100" id="my-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th style={{ textAlign: 'center' }}>City</th>
                                <th style={{ textAlign: 'center' }}>Activated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((author, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>{author.name}</td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <img src={`https://nrdruhs.online/uploads/${author.image}`} alt="authorImg" className="authorImgList" />
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{author.email}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{author.role}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{author.city}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                            {author.is_activated === 1
                                                ? <span style={{ backgroundColor: 'green', color: 'white', textAlign: 'center', paddingLeft: 6, paddingRight: 6 }}>Yes</span>
                                                : <span style={{ backgroundColor: 'red', color: 'white', textAlign: 'center', paddingLeft: 6, paddingRight: 6 }}>No</span>}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {renderPagination()}
            </div>

            <div style={{ textAlign: 'center', padding: 60 }}>


                <button style={{ padding: 15, fontSize: 21, width: 290, marginRight: 6 }}
                    className="btn btn-info"
                    onClick={() => navigate('/listarticlepublish')}
                >
                    <i className="fa fa-upload" aria-hidden="true" style={{ marginRight: '5px', fontSize: 21 }}></i> Publish An Article
                </button>



                <button style={{ padding: 15, fontSize: 21, width: 290, marginRight: 6 }}
                    className="btn btn-primary"
                    onClick={() => navigate('/listartists')}
                >
                    <i className="fa fa-user-times" aria-hidden="true" style={{ marginRight: '5px', fontSize: 21 }}></i> Deactivate An Author
                </button>

                <button style={{ padding: 15, fontSize: 21, width: 290, marginRight: 6 }}
                    className="btn btn-warning"
                    onClick={downloadList} // Call the downloadList function
                >
                    <i className="fa fa-cloud-download" aria-hidden="true" style={{ marginRight: '5px', fontSize: 21 }}></i> Download List
                </button>
            </div>
        </div>
    );
}

export default ListAuthorDashboard;
