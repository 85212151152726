import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from 'sweetalert2';

import { NavDropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';

import logoImage from './UttamSindhiKalakarLogoNew9.png'; // Import the logo image

import './MainPage.css'; // Import the custom CSS file
import UttamKalakarFooter from './UttamKalakarFooter';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = () => {
        axios
            .post('https://nrdruhs.online/login', { email, password })
            .then((response) => {
                const { token, data } = response.data;
                const { id, email } = data;

                sessionStorage.setItem('token', token); // Store the token in sessionStorage
                sessionStorage.setItem('loggedInUser', email); // Store the email in sessionStorage
                sessionStorage.setItem('userId', id); // Store the id in sessionStorage with key 'userId'


                navigate('/'); // Redirect to the dashboard
                window.location.reload(); // Refresh the page
            })
            .catch((error) => {
                console.error('Login error:', error);

                // Display a SweetAlert2 error message
                Swal.fire({
                    icon: 'error',
                    title: 'Login Failed',
                    text: 'Invalid email or password',
                });
            });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
        }
    };

    const [scrolling, setScrolling] = useState(false);

    // Function to handle the scroll event
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [showMenu, setShowMenu] = useState(false);

    // Function to toggle the visibility of the navigation links
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        // Initialize AOS library
        AOS.init();
    }, []);



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <div className="custom-container">
            <div>
                {/* Top Navigation Bar */}
                <nav className={`navBar ${scrolling ? 'scrolling' : ''}`} data-aos="fade-down" data-aos-duration="1200">

                    {/* Logo Image */}
                    <img src={logoImage} alt="Logo" className="logo" />

                    {/* Hamburger Menu Button */}
                    <div className="menuButton" onClick={toggleMenu}>
                        <div className={`menuIcon ${showMenu ? 'open' : ''}`} />
                    </div>

                    {/* Navigation Links */}
                    <ul className={`navLinks ${showMenu ? 'show' : ''}`}>
                        <li>
                            <Link to="/" className="link">Home</Link>
                        </li>
                        <li>
                            <Link to="/aboutus" className="link">About NRD</Link>
                        </li>
                        <li>
                            <Link to="/listuserscards" className="link">Editorial Board</Link>
                        </li>
                        <li>
                            <Link to="/articleupload" className="link">Current Issues</Link>
                        </li>
                        <li>
                            <Link to="/articleupload" className="link">Other Issues</Link>
                        </li>


                        <NavDropdown title="Login" id="basic-nav-dropdown" style={{ fontWeight: 'bold', marginRight: 18 }}>
                            <NavDropdown.Item href="login">
                                <FaUser style={{ marginRight: 6, color: 'DodgerBlue' }} /> Author
                            </NavDropdown.Item>
                            <NavDropdown.Item href="reviewlogin">
                                <FaUser style={{ marginRight: 6, color: 'DodgerBlue' }} /> Reviewer
                            </NavDropdown.Item>
                            <NavDropdown.Item href="adminlogin">
                                <FaUser style={{ marginRight: 6, color: 'DodgerBlue' }} /> Admin
                            </NavDropdown.Item>
                        </NavDropdown>


                        <li>
                            <Link to="/register" className="btn btn-danger btn-lg btn-block" style={{ marginTop: -10, fontSize: 15, fontWeight: 'bold', color: 'white' }}>
                                <i className="fas fa-user-plus" style={{ paddingRight: 6, fontSize: 15, verticalAlign: 'middle' }} /> Register
                            </Link>
                        </li>


                    </ul>



                </nav>
            </div>

            <div className="main-content">
                <img
                    src="/assets/NRDRUHSHomeBanner.jpg"
                    alt="Background"
                    className="header-image"
                    style={{ width: '100%', height: '60vh', objectFit: 'cover' }}
                />
            </div>

            <div className="main-content" style={{ padding: 30, paddingBottom: 120, marginTop: -120, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-4 col-md-6 col-sm-8 col-10">
                        {/* Wrap the login form with card */}
                        <div className="card p-4 shadow" data-aos="fade-up" data-aos-duration="1200" style={{ background: '#B3E7FF' }} >
                            <h3 className="mb-4 text-center">Login</h3>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyDown={handleKeyDown} // Add keydown event listener
                                    />
                                </div>
                                <br />
                                <div className="form-group">
                                    <label htmlFor="password" style={{ fontWeight: 'bold' }}>Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append" style={{ paddingTop: 3 }}>
                                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                                <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div style={{ textAlign: 'center' }}>
                                    <button type="button" onClick={handleLogin} className="btn btn-primary btn-block">Login</button>
                                </div>
                            </form>
                            <Link to="/forgotpassword">Forgot Password</Link>
                        </div>
                    </div>
                </div>
            </div>
            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>
        </div>
    );
};

export default Login;
