import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card, Button } from 'react-bootstrap'; // Assuming you're using Bootstrap for the card, dropdown, and button components
import UttamKalakarFooter from './UttamKalakarFooter';

const MyReview = () => {
    const [user, setUser] = useState(null);
    const [titlesList, setTitlesList] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState(null);

    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const loggedInUser = sessionStorage.getItem('loggedInUser');
                console.log('loggedInUser:', loggedInUser);

                const response = await axios.get(`https://nrdruhs.online/api/manuscriptTitlesMyReview`, {
                    params: {
                        reviewby: user, // Pass the user's name as reviewby
                    },
                });
                setTitlesList(response.data.titles);
            } catch (error) {
                console.error('Error fetching titles:', error.message);
            }
        };

        fetchTitles();
    }, [user]); // Add user to the dependency array to trigger the effect when user changes

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const isAuthenticated = sessionStorage.getItem('token');
                const response = await axios.get('https://nrdruhs.online/userdashboard', {
                    headers: {
                        Authorization: `Bearer ${isAuthenticated}`,
                    },
                });
                setUser(response.data.name);
                console.log('User data:', response.data.name);
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        fetchUser();
    }, []);




    const handleDownloadArticle = async () => {
        if (selectedTitle && selectedTitle.editedarticle) {
            try {
                const response = await axios({
                    url: `https://nrdruhs.online/uploads/${selectedTitle.editedarticle}`,
                    method: 'GET',
                    responseType: 'blob', // Important to set the response type to blob
                });

                // Create a link element and simulate a click to download the file
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', selectedTitle.editedarticle);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                console.error('Error downloading article:', error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong while downloading the article!',
                });
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'No Article Selected',
                text: 'Please select an article to download.',
            });
        }
    };

    return (
        <div>
            <div className="main-content">
                <img
                    src="/assets/NRDRUHSHomeBanner.jpg"
                    alt="Background"
                    className="header-image"
                    style={{ width: '100%', height: '60vh', objectFit: 'cover' }}
                />
            </div>

            <div style={{ marginTop: 90, marginBottom: 90 }}>
                <Card style={{ margin: '0 auto', maxWidth: 600, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
                    <Card.Body>
                        <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>Assign Author to Review</h3>
                        <div className="col-sm-12">
                            <select
                                className="form-select form-select-lg mb-3 mb-lg-0"
                                value={selectedTitle ? selectedTitle.title : ''}
                                onChange={(e) => {
                                    const title = titlesList.find(t => t.title === e.target.value);
                                    console.log('Selected title:', title); // Log selected title
                                    setSelectedTitle(title);
                                }}
                                style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                                <option value="" style={{ fontSize: '18px', fontWeight: 'bold' }}>Select a title</option>
                                {titlesList.map((titleObject) => {
                                    const displayTitle = titleObject.title.length > 66 ?
                                        `${titleObject.title.substring(0, 66)}...` :
                                        titleObject.title;
                                    return (
                                        <option
                                            key={titleObject.title}
                                            value={titleObject.title}
                                            style={{ fontSize: '18px' }}
                                        >
                                            {displayTitle} - {titleObject.articleid}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </Card.Body>
                </Card>

                <div className="text-center mt-3">
                    <Button variant="primary" onClick={handleDownloadArticle}>
                        Download Article
                    </Button>
                </div>
            </div>

            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>
        </div>
    );
};

export default MyReview;
