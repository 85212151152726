import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './ListArtists.css';
import Swal from 'sweetalert2'; // Import SweetAlert2
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';

function ListArtists() {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/listusers')
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const handleToggle = (index) => {
        Swal.fire({
            title: 'Confirm',
            text: 'Do you want to enable/disable this artist?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedData = [...data];
                updatedData[index].is_activated = updatedData[index].is_activated ? 0 : 1;
                setData(updatedData);

                const authorId = updatedData[index].id;
                const updatedValue = updatedData[index].is_activated;
                axios
                    .post('https://nrdruhs.online/updateartists', { id: authorId, is_activated: updatedValue })
                    .then((res) => {
                        // Handle the successful update if needed
                        Swal.fire('Success', 'The artist has been updated.', 'success');
                    })
                    .catch((err) => {
                        console.log(err);
                        // Handle the error if needed
                        Swal.fire('Error', 'An error occurred while updating the artist.', 'error');
                    });
            }
        });
    };

    useEffect(() => {
        axios
            .get('https://nrdruhs.online/usersnewsearch', {
                params: { search: searchTerm },
            })
            .then((res) => {
                if (res.data.Status === 'Success') {
                    setData(res.data.Result);
                } else {
                    alert('Error');
                }
            })
            .catch((err) => console.log(err));
    }, [searchTerm]);

    // Filter the data based on the search term
    const filteredData = data.filter((author) =>
        author.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (


        <div>
            <UttamKalakarHeader />
            <div style={{ paddingLeft: 45, paddingRight: 45, paddingTop: 60 }}>
                <h2 className="text-center">Deactivate An Artist</h2>
            </div>
            <div className="container-fluid px-0">
                <div className="px-5 py-3">
                    <div className='d-flex justify-content-between align-items-center mt-2'>
                        <h3 style={{ paddingBottom: 15 }}>
                            <strong></strong>
                        </h3>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='me-3'>
                            <label className='form-label me-1' style={{ fontWeight: 'bold' }}>Search Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Search by name'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mt-3" data-aos="fade-up" data-aos-duration="1800">
                        <table className="table w-100" id="my-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Image</th>
                                    <th>Email</th>
                                    <th>Category</th>
                                    <th>City</th>
                                    <th>Activated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((author, index) => (
                                    <tr key={index}>
                                        <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>{author.name}</td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <img src={`https://nrdruhs.online/uploads/${author.image}`} alt="authorImg" className="authorImgList" />
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{author.email}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{author.designation}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{author.city}</td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <label className="switch">
                                                <input type="checkbox" checked={author.is_activated === 1} onChange={() => handleToggle(index)} />
                                                <span className="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row" style={{ padding: 60 }}>

            </div>
            <UttamKalakarFooter />

        </div>

    );
}

export default ListArtists;
