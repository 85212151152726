import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card } from 'react-bootstrap'; // Assuming you're using Bootstrap for the card, dropdown, and button components

import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';

function ReviewUpload() {

    const [titlesList, setTitlesList] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [mainDocument, setMainDocument] = useState(null);
    const [uploadedMainDocuments, setUploadedMainDocuments] = useState([]);
    const [reviewfeedback, setReviewFeedback] = useState(''); // State for feedback
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const loggedInUser = sessionStorage.getItem('loggedInUser');
                console.log('loggedInUser:', loggedInUser);
                const response = await axios.get(`https://nrdruhs.online/api/manuscriptTitlesReviewDone`);
                setTitlesList(response.data.titles);
            } catch (error) {
                console.error('Error fetching titles:', error.message);
            }
        };
        fetchTitles();
    }, []);

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem('token');

        if (isAuthenticated) {
            axios.get('https://nrdruhs.online/userdashboard', {
                headers: {
                    Authorization: `Bearer ${isAuthenticated}`,
                },
            })
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {
                    console.error('Failed to fetch user data:', err);
                });
        } else {
            console.error('User is not authenticated');
        }
    }, []);

    useEffect(() => {
        fetchUploadedMainDocuments();
    }, [userId]);

    const fetchUploadedMainDocuments = () => {
        axios
            .get(`https://nrdruhs.online/upload/displaystatusmd?userId=${userId}`)
            .then((response) => {
                setUploadedMainDocuments(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleMainDocumentUpload = (event) => {
        const file = event.target.files[0];
        setMainDocument(file);
    };

    const handleMainDocumentSubmit = () => {
        if (!mainDocument || !selectedTitle) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please select a title and a file before submitting.',
            });
            return; // No file or title selected, handle validation or display an error message
        }

        const selectedArticleId = titlesList.find(titleObject => titleObject.title === selectedTitle)?.articleid;

        if (!selectedArticleId) {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Invalid title selected.',
            });
            return;
        }

        const formData = new FormData();
        formData.append('maindocument', mainDocument);
        formData.append('articleid', selectedArticleId);
        formData.append('reviewfeedback', reviewfeedback); // Append feedback to the form data

        console.log('Review Feedback:', reviewfeedback); // Add this console log

        axios
            .post('https://nrdruhs.online/upload/uploadreviewarticle', formData)
            .then((response) => {
                console.log(response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'File uploaded successfully.',
                }).then(() => {
                    fetchUploadedMainDocuments();
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Failed to upload file.',
                });
                console.error(error);
            });
    };


    const sendEmailToAdmin = () => {
        if (mainDocument) {
            const message = `Hello Admin,\n\nThe Reviewer ${user.name} has uploaded the Reviewed Article. Please check. \n\nThanks Web Administrator`;

            axios.post('https://nrdruhs.online/sendemailtoadminreviewer', {
                email: 'pradeepkhemnani2018@gmail.com',
                message: message
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Uploaded and Email sent to Admin successfully.'
                    });
                })
                .catch(error => {
                    console.error('Error sending email to admin:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Failed to send email to admin. Please try again later.'
                    });
                });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please upload the reviewed document before saving.'
            });
        }
    };

    return (
        <div>
            <UttamKalakarHeader />
            <div className="container" style={{ paddingTop: 45, paddingBottom: 45 }}>
                <h3 className="mt-2" style={{ textAlign: 'center', padding: 30, fontWeight: 'bold' }}>UPLOAD REVIEWED ARTICLE</h3>
                <Card style={{ margin: '0 auto', maxWidth: 600, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
                    <Card.Body>
                        <div className="col-sm-12">
                            <select
                                className="form-select form-select-lg mb-3 mb-lg-0"
                                value={selectedTitle}
                                onChange={(e) => setSelectedTitle(e.target.value)}
                                style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                                <option value="" style={{ fontSize: '18px', fontWeight: 'bold' }}>Select a title</option>
                                {titlesList.map((titleObject) => {
                                    const displayTitle = titleObject.title.length > 66 ?
                                        `${titleObject.title.substring(0, 66)}...` :
                                        titleObject.title;
                                    return (
                                        <option
                                            key={titleObject.title}
                                            value={titleObject.title}
                                            style={{ fontSize: '18px' }}
                                        >
                                            {displayTitle} - {titleObject.articleid}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </Card.Body>
                </Card>


                <div className="mt-4">
                    <Card style={{ backgroundColor: '#dfdfdf' }}>
                        <Card.Body>
                            <h5 className="card-title" style={{ textAlign: 'center' }}>Feedback</h5>
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    value={reviewfeedback}
                                    onChange={(e) => setReviewFeedback(e.target.value)}
                                    rows="4"
                                    placeholder="Enter your feedback here..."
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </div>



                <div className="card mt-4" style={{ backgroundColor: '#dfdfdf' }}>
                    <div className="card-body">
                        <h5 className="card-title" style={{ textAlign: 'center' }}>Reviewed Document</h5>
                        <div className="form-group">
                            <div className="custom-file">
                                <input type="file" id="mainDocument" className="custom-file-input" onChange={handleMainDocumentUpload} />
                                <label className="custom-file-label" htmlFor="mainDocument">
                                    {mainDocument ? mainDocument.name : ''}
                                </label>
                            </div>
                        </div><br />
                        <button className="btn btn-primary" onClick={handleMainDocumentSubmit}>
                            Upload Reviewed Article
                        </button>

                        {uploadedMainDocuments.length > 0 && (
                            <div className="mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Uploaded Reviewed Article</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                </table>
                            </div>
                        )}
                    </div>
                </div>



                {/* Button to send email to admin */}
                <div className="text-center mt-4">
                    <button className="btn btn-warning" onClick={sendEmailToAdmin} style={{ fontSize: '21px', fontWeight: 'bold' }}>CONFIRM UPLOAD & SAVE</button>
                </div>

            </div>
            <UttamKalakarFooter />
        </div>
    );
}

export default ReviewUpload;
