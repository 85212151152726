import React, { useState } from 'react';
import axios from 'axios';

const SendTestEmail = () => {
    const [email, setEmail] = useState('');

    const handleSendTestEmail = () => {
        // Send a POST request to the backend API
        axios.post('https://nrdruhs.online/api/send-test-email', { email })
            .then(response => {
                console.log(response.data);
                alert('Test email sent successfully!');
            })
            .catch(error => {
                console.error(error);
                alert('Failed to send test email. Please check the server logs for more details.');
            });
    };

    return (
        <div>
            <h2>Send Test Email</h2>
            <div>
                <label>Email:</label>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <button onClick={handleSendTestEmail}>Send Test Email</button>
        </div>
    );
};

export default SendTestEmail;
