// AuthorDashboard.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NRDRUHSHeader from './NRDRUHSHeader';
import NRDRUHSFooter from './NRDRUHSFooter';

const AuthorDashboard = () => {
    const [manuscript, setManuscript] = useState(null);
    const userId = sessionStorage.getItem('userId'); // Assuming userId is stored in sessionStorage

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://82.180.139.209:5000/api/manuscript/${userId}`);
                setManuscript(response.data);
            } catch (error) {
                console.error('Error fetching manuscript:', error.message);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId]);

    return (

        <div>
            <NRDRUHSHeader />

            <div style={{ marginTop: 30, textAlign: 'center', marginBottom: 60, padding: 30 }}>
                <h2 style={{ marginBottom: 30, fontWeight: 'bold' }}>Author's Dashboard</h2>
                {manuscript ? (
                    <div>
                        <h3>Manuscript Form Details</h3>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>Article Type</th>
                                    <th>Title</th>
                                    <th>Word Count</th>
                                    <th>Images</th>
                                    <th>Tables</th>
                                    <th>Pages</th>
                                    <th>Keywords</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{manuscript.userId}</td>

                                    <td>{manuscript.articletype}</td>
                                    <td>{manuscript.title}</td>

                                    <td>{manuscript.wordcount}</td>
                                    <td>{manuscript.images}</td>
                                    <td>{manuscript.tables}</td>
                                    <td>{manuscript.pages}</td>
                                    <td>{manuscript.keywords}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>

            <NRDRUHSFooter />
        </div>
    );
};

export default AuthorDashboard;
