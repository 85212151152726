import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './MainLayout.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logoImage from './UttamSindhiKalakarLogoNew9.png'; // Import the logo image
import Home from './Home';
import { NavDropdown } from 'react-bootstrap';
import { FaUser, FaUserCheck, FaUserTie } from 'react-icons/fa';

import '@fortawesome/fontawesome-free/css/all.min.css';



const MainPage = () => {


    const [scrolling, setScrolling] = useState(false);

    // Function to handle the scroll event
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [showMenu, setShowMenu] = useState(false);

    // Function to toggle the visibility of the navigation links
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };




    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="custom-container">
            <div>
                <nav className={`navBar ${scrolling ? 'scrolling' : ''}`} data-aos="fade-down" data-aos-duration="1200">

                    {/* Logo Image */}
                    <img src={logoImage} alt="Logo" className="logo" />

                    {/* Hamburger Menu Button */}
                    <div className="menuButton" onClick={toggleMenu}>
                        <div className={`menuIcon ${showMenu ? 'open' : ''}`} />
                    </div>

                    {/* Navigation Links */}
                    <ul className={`navLinks ${showMenu ? 'show' : ''}`}>
                        <li>
                            <Link to="/" className="link">Home</Link>
                        </li>
                        <li>
                            <Link to="" className="link">About NRD</Link>
                        </li>
                        <li>
                            <Link to="" className="link">Editorial Board</Link>
                        </li>
                        <li>
                            <Link to="" className="link">Current Issues</Link>
                        </li>
                        <li>
                            <Link to="" className="link">Other Issues</Link>
                        </li>


                        <NavDropdown title="Login" id="basic-nav-dropdown" style={{ fontWeight: 'bold' }}>
                            <NavDropdown.Item href="login">
                                <FaUser style={{ marginRight: 6, color: 'Navy' }} /> Author
                            </NavDropdown.Item>
                            <NavDropdown.Item href="reviewlogin">
                                <FaUserTie style={{ marginRight: 6, color: 'Chocolate', fontSize: 16 }} /> Reviewer
                            </NavDropdown.Item>
                            <NavDropdown.Item href="adminlogin">
                                <FaUserCheck style={{ marginRight: 6, color: 'green', fontSize: 20 }} /> Admin
                            </NavDropdown.Item>
                        </NavDropdown>






                    </ul>

                    <NavDropdown title="Register" id="basic-nav-dropdown" style={{ fontWeight: 'bold', marginRight: 18, backgroundColor: 'red', padding: 15 }}>
                        <NavDropdown.Item href="/register">
                            <FaUser style={{ marginRight: 6, color: 'Navy' }} /> As an Author
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/registerreviewer">
                            <FaUserTie style={{ marginRight: 6, color: 'red', fontSize: 18 }} /> As a Reviewer
                        </NavDropdown.Item>
                    </NavDropdown>



                </nav>
            </div>


            <div className="main-content" style={{ padding: 0, backgroundColor: '#efefef' }}>
                <Home />
            </div>
        </div>

    );
};

export default MainPage;
