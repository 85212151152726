import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import './EditUserProfile.css';
import UttamKalakarHeader from './UttamKalakarHeader';
import UttamKalakarFooter from './UttamKalakarFooter';

const EditUserProfile = () => {
    const location = useLocation();
    const { user } = location.state;
    const navigate = useNavigate();

    const [name, setName] = useState(user.name || '');
    const [gender, setGender] = useState(user.gender || '');
    const [mobilenumber, setMobileNumber] = useState(user.mobilenumber || '');
    const [address, setAddress] = useState(user.address || '');
    const [state, setState] = useState(user.state || '');
    const [city, setCity] = useState(user.city || '');

    const [currentStep, setCurrentStep] = useState(1);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const userData = {
            id: user.id,
            name: name,
            gender: gender,
            address: address,
            state: state,
            city: city,
            mobilenumber: mobilenumber,
        };

        axios
            .post('https://nrdruhs.online/editusernew', userData)
            .then((response) => {
                console.log('User profile updated successfully');
                Swal.fire('Success', 'Your profile has been saved successfully.', 'success');
            })
            .catch((error) => {
                console.error('Error updating user profile:', error);
            });
    };

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleMyProfile = () => {
        navigate(-1);
    };

    return (
        <div>
            <UttamKalakarHeader />
            <div className="container" style={{ paddingTop: 45 }}>
                <div style={{ textAlign: 'right' }}>
                    <button className="btn btn-warning" onClick={handleMyProfile}>
                        <strong>Back to My Profile</strong>
                    </button>
                </div>
                <h2 className="text-center mb-4">Update Your Profile</h2>
                <div className="stepper">
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${(currentStep - 1) * 50}%` }}></div>
                    </div>
                    <ul className="steps" style={{ marginTop: -20 }}>
                        <li className={currentStep === 1 ? 'active' : ''}>
                            <div className="step-number">1</div>
                            <div className="step-description">Personal Information</div>
                        </li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        {currentStep === 1 && (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name" className="input-label">
                                        <strong>Name of the Artist</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control input-field"
                                        id="name"
                                        value={name}
                                        onChange={handleNameChange}
                                    />
                                </div>
                                <div className="inline-inputs">
                                    <div className="form-group">
                                        <label htmlFor="gender" className="input-label">
                                            <strong>Gender</strong>
                                        </label>
                                        <select
                                            className="form-control input-field"
                                            id="gender"
                                            value={gender}
                                            onChange={handleGenderChange}
                                        >
                                            <option value=""></option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobilenumber" className="input-label">
                                            <strong>Mobile</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            id="mobilenumber"
                                            value={mobilenumber}
                                            onChange={handleMobileNumberChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="state" className="input-label">
                                            <strong>State</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input-field-short"
                                            id="state"
                                            value={state}
                                            onChange={handleStateChange}
                                        />
                                    </div>
                                </div>
                                <div className="inline-inputs">
                                    <div className="form-group">
                                        <label htmlFor="address" className="input-label">
                                            <strong>Postal Address</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input-field"
                                            id="address"
                                            value={address}
                                            onChange={handleAddressChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="city" className="input-label">
                                            <strong>City</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control input-field-short"
                                            id="city"
                                            value={city}
                                            onChange={handleCityChange}
                                        />
                                    </div>
                                </div>
                                <div className="button-container" style={{ paddingBottom: 60 }}>
                                    {currentStep !== 1 && (
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            style={{ marginRight: 12 }}
                                            onClick={handlePrev}
                                        >
                                            Previous
                                        </button>
                                    )}
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>

            <UttamKalakarFooter />
        </div>
    );
};

export default EditUserProfile;
