import React from 'react';
import styles from './NRDRUHSHeader.module.css'; // Import scoped styles

function NRDRUHSHeader() {
    return (
        <div className={styles['main-content']}>
            <img
                src="/assets/NRDRUHSHomeBanner.jpg"
                alt="Background"
                className={styles['header-image']}
            />
        </div>
    );
}

export default NRDRUHSHeader;
