import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavDropdown } from 'react-bootstrap';
import { FaUser, FaUserCheck, FaUserTie } from 'react-icons/fa';

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import logoImage from './UttamSindhiKalakarLogoNew9.png'; // Import the logo image

import './MainPage.css'; // Import the custom CSS file
import UttamKalakarFooter from './UttamKalakarFooter';

function RegisterPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add isLoading state
    const navigate = useNavigate(); // Initialize useNavigate hook

    axios.defaults.withCredentials = true;

    const handleRegistration = () => {
        const defaultImageName = 'uttamkalakaruserimg.png';
        setIsLoading(true); // Show loading animation

        axios.post('https://nrdruhs.online/api/register', { name, email, password, image: defaultImageName })
            .then(response => {
                // console.log(response.data);

                // Display a success message using SweetAlert2
                Swal.fire({
                    icon: 'success',
                    title: 'Registration Successful',
                    html: 'You have been registered successfully. Please check your <strong>Email to Activate</strong> your account.'
                }).then(() => {
                    // Check if the user is activated
                    // console.log(response.data);
                    if (response.data.is_activated === 1) {
                        navigate('/');
                    }
                });
            })
            .catch(error => {
                console.error(error);
                // Display an error message or redirect to an error page
                if (error.response && error.response.status === 409) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Registration Failed',
                        text: 'User already exists with this email.'
                    });
                }
            })
            .finally(() => {
                setIsLoading(false); // Hide loading animation
            });
    };


    const [scrolling, setScrolling] = useState(false);

    // Function to handle the scroll event
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [showMenu, setShowMenu] = useState(false);

    // Function to toggle the visibility of the navigation links
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };


    useEffect(() => {
        // Initialize AOS library
        AOS.init();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (


        <div className="custom-container">
            <div>
                {/* Top Navigation Bar */}
                <nav className={`navBar ${scrolling ? 'scrolling' : ''}`} data-aos="fade-down" data-aos-duration="1200">

                    {/* Logo Image */}
                    <img src={logoImage} alt="Logo" className="logo" />

                    {/* Hamburger Menu Button */}
                    <div className="menuButton" onClick={toggleMenu}>
                        <div className={`menuIcon ${showMenu ? 'open' : ''}`} />
                    </div>

                    {/* Navigation Links */}
                    <ul className={`navLinks ${showMenu ? 'show' : ''}`}>
                        <li>
                            <Link to="/" className="link">Home</Link>
                        </li>
                        <li>
                            <Link to="/aboutus" className="link">About NRD</Link>
                        </li>
                        <li>
                            <Link to="/listuserscards" className="link">Editorial Board</Link>
                        </li>
                        <li>
                            <Link to="/articleupload" className="link">Current Issues</Link>
                        </li>
                        <li>
                            <Link to="/articleupload" className="link">Other Issues</Link>
                        </li>


                        <NavDropdown title="Login" id="basic-nav-dropdown" style={{ fontWeight: 'bold' }}>
                            <NavDropdown.Item href="login">
                                <FaUser style={{ marginRight: 6, color: 'Navy' }} /> Author
                            </NavDropdown.Item>
                            <NavDropdown.Item href="reviewlogin">
                                <FaUserTie style={{ marginRight: 6, color: 'Chocolate', fontSize: 16 }} /> Reviewer
                            </NavDropdown.Item>
                            <NavDropdown.Item href="adminlogin">
                                <FaUserCheck style={{ marginRight: 6, color: 'green', fontSize: 20 }} /> Admin
                            </NavDropdown.Item>
                        </NavDropdown>






                    </ul>

                    <NavDropdown title="Register" id="basic-nav-dropdown" style={{ fontWeight: 'bold', marginRight: 18, backgroundColor: 'red', padding: 15 }}>
                        <NavDropdown.Item href="/register">
                            <FaUser style={{ marginRight: 6, color: 'Navy' }} /> As an Author
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/registerreviewer">
                            <FaUserTie style={{ marginRight: 6, color: 'red', fontSize: 18 }} /> As a Reviewer
                        </NavDropdown.Item>
                    </NavDropdown>



                </nav>
            </div>
            <div className="main-content">
                <img
                    src="/assets/NRDRUHSHomeBanner.jpg"
                    alt="Background"
                    className="header-image"
                    style={{ width: '100%', height: '60vh', objectFit: 'cover' }}
                />
            </div>

            <div className="main-content" style={{ padding: 30, paddingBottom: 120, marginTop: -120, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)' }}>
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-4 col-md-6 col-sm-8 col-10">
                        {/* Wrap the login form with card */}
                        <div className="card p-4 shadow" data-aos="fade-up" data-aos-duration="1200" style={{ background: '#B3E7FF' }}>
                            <h3 className="mb-4 text-center">Register As Author</h3>
                            <form>
                                <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                                    <label htmlFor="name" style={{ fontWeight: 'bold' }}>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                                    <label htmlFor="email" style={{ fontWeight: 'bold' }}>Email</label>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                                    <label htmlFor="password" style={{ fontWeight: 'bold' }}>Password</label>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                                <br />
                                <div className="button-container"> {/* Center the button within the card */}
                                    <button type="button" onClick={handleRegistration} className="btn btn-primary btn-block" disabled={isLoading}>
                                        {isLoading ? (
                                            <div className="loader-container">
                                                <div className="loader" />
                                            </div>
                                        ) : (
                                            'Register'
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <UttamKalakarFooter />

            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>

            </div>
        </div>


    );
}

export default RegisterPage;
