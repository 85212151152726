import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import logoImage from './UttamSindhiKalakarLogoNew9.png';

function LoginNew() {
    const navigate = useNavigate(); // Define the navigate function

    const handleRegisterLogin = () => {
        navigate('/login');
    };

    const handleRegisterLoginReviewer = () => {
        navigate('/reviewlogin');
    };

    return (
        <div style={{ height: '100vh', textAlign: 'center', paddingTop: 90, paddingBottom: 30, background: 'linear-gradient(to right, #5433ff, #20bdff, #a5fecb)', verticalAlign: 'center' }}>
            {/* Logo Image */}
            <img src={logoImage} alt="Logo" className="logo" />
            <h3 style={{ padding: 30, paddingTop: 30, paddingBottom: 30 }}>Welcome! You have been successfully registered. <br /><br />Please Login to get access your Dashboard. </h3>

            <div>
                <button
                    type="button"
                    onClick={handleRegisterLogin}
                    className="btn btn-primary"
                    style={{ fontSize: 24, padding: '10px 20px' }} // Adjust padding as needed
                >
                    Login Author
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                    type="button"
                    onClick={handleRegisterLoginReviewer}
                    className="btn btn-danger"
                    style={{ fontSize: 24, padding: '10px 20px' }} // Adjust padding as needed
                >
                    Login Reviewer
                </button>
            </div>


        </div>

    )

}

export default LoginNew;
