import React from 'react';
import Stepper from 'react-stepper-horizontal';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

import UttamKalakarFooter from './UttamKalakarFooter';

const steps = [
    { title: 'Upload Articles', route: '/articleupload', color: 'blue' }, // Assign blue color to the first step
    { title: 'ManuScript Form', route: '/updatemanuscriptform', color: 'blue' },
    { title: 'Update Authors', route: '/updateauthors', color: 'blue' },

];

const ManuScriptSteps = () => {
    const navigate = useNavigate(); // Initialize useNavigate for navigation

    const handleButtonClick = (route) => {
        navigate(route); // Navigate to the specified route
    };

    return (


        <div style={{ paddingTop: 200 }}>
            <Stepper
                steps={steps}
                activeStep={steps.length - 1} // Set the activeStep to the last step
                circleFontSize={16}
                defaultTitleColor="black"
                circleTop={0}
                circleLeft={0}


            />
            <div className="button-container" style={{ marginTop: '60px', display: 'flex' }}>
                {steps.map((step, index) => (
                    <button
                        key={index}
                        className={`btn btn-primary btn-lg ${step.color === 'blue' ? 'btn-blue' : 'btn-green'}`}
                        style={{ margin: '5px', padding: '20px 20px', width: '300px', fontWeight: 'bold' }} // Set width to 400px
                        onClick={() => handleButtonClick(step.route)}
                    >
                        {step.title}
                    </button>
                ))}
            </div>

            <div style={{ padding: 60 }}></div>


            <UttamKalakarFooter />
        </div>



    );
};

export default ManuScriptSteps;
