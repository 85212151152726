import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card, Dropdown, Button } from 'react-bootstrap'; // Assuming you're using Bootstrap for the card, dropdown, and button components
import UttamKalakarFooter from './UttamKalakarFooter';
import { jsPDF } from 'jspdf';

const IssueCertificateReview = () => {
    const [titlesList, setTitlesList] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState('');

    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const loggedInUser = sessionStorage.getItem('loggedInUser');
                console.log('loggedInUser:', loggedInUser);
                const response = await axios.get(`https://nrdruhs.online/api/manuscriptTitlesCertIssue`);
                setTitlesList(response.data.titles);
            } catch (error) {
                console.error('Error fetching titles:', error.message);
            }
        };

        const fetchAuthors = async () => {
            try {
                const response = await axios.get(`https://nrdruhs.online/api/authors?role=Reviewer`);
                setAuthors(response.data.authors);
            } catch (error) {
                console.error('Error fetching authors:', error.message);
            }
        };

        fetchTitles();
        fetchAuthors();
    }, []);

    const handleCertIssue = async () => {
        try {
            // Make sure a title and author are selected
            if (!selectedTitle || !selectedAuthor) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select a title and an author',
                });
                return;
            }

            // Send a request to update the manuscript with the selected author
            await axios.put(`https://nrdruhs.online/api/issueCertReview`, {
                title: selectedTitle,
                author: selectedAuthor,
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Certificate has been issued successfully',
            });
        } catch (error) {
            console.error('Error assigning author:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while assigning the author',
            });
        }
    };

    const handleGeneratePDFCertificate = async () => {
        // Make sure a title and author are selected
        if (!selectedTitle || !selectedAuthor) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select a title and an author',
            });
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'a4'
        });

        const imageUrl = '/assets/CertificateNRDRUHSBG.jpg';

        // Load the image
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            doc.addImage(image, 'JPEG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);

            const leftMargin = 210;
            const pageWidth = doc.internal.pageSize.width;

            doc.setFont('helvetica', 'bold'); // Set font to bold
            doc.setFontSize(21);
            doc.setTextColor(204, 102, 0); // Set text color to #999999
            doc.text(selectedAuthor, (pageWidth - leftMargin) / 2 + leftMargin, 300, { align: 'center' });

            doc.setFontSize(12);
            doc.setTextColor(204, 102, 0); // Set text color to #999999
            doc.text(selectedTitle.toUpperCase(), (pageWidth - leftMargin) / 2 + leftMargin, 395, { align: 'center' });

            doc.save('Certificate.pdf');
        };


    };

    return (
        <div>
            <div className="main-content">
                <img
                    src="/assets/NRDRUHSHomeBanner.jpg"
                    alt="Background"
                    className="header-image"
                    style={{ width: '100%', height: '60vh', objectFit: 'cover' }}
                />
            </div>

            <div style={{ marginTop: 90, marginBottom: 90 }}>
                <Card style={{ margin: '0 auto', maxWidth: 600 }}>
                    <Card.Body>
                        <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>Title - Issue Certificate</h3>
                        <div className="col-sm-12">
                            <select
                                className="form-select form-select-lg mb-3 mb-lg-0"
                                value={selectedTitle}
                                onChange={(e) => setSelectedTitle(e.target.value)}
                                style={{ fontSize: '16px', fontWeight: 'bold' }}
                            >
                                <option value="" style={{ fontSize: '18px', fontWeight: 'bold' }}>Select a title</option>
                                {titlesList.map((titleObject) => {
                                    const displayTitle = titleObject.title.length > 66 ?
                                        `${titleObject.title.substring(0, 66)}...` :
                                        titleObject.title;
                                    return (
                                        <option
                                            key={titleObject.title}
                                            value={titleObject.title}
                                            style={{ fontSize: '18px' }}
                                        >
                                            {displayTitle} - {titleObject.articleid}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                    </Card.Body>
                </Card>

                <Card style={{ margin: '20px auto', maxWidth: 600 }}>
                    <Card.Body>
                        <h3 style={{ textAlign: 'center', padding: 15, fontWeight: 'bold', fontSize: 18 }}>Select Author</h3>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-authors">
                                {selectedAuthor ? selectedAuthor : 'Select an Author'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {authors.map((author) => (
                                    <Dropdown.Item
                                        key={author.id}
                                        onClick={() => setSelectedAuthor(author.name)}
                                    >
                                        {author.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Body>
                </Card>

                <div className="text-center mt-3">
                    <Button variant="danger" onClick={handleCertIssue} style={{ marginRight: '10px' }}>
                        Issue Certificate
                    </Button>
                    <Button variant="success" onClick={handleGeneratePDFCertificate}>
                        Generate PDF Certificate
                    </Button>
                </div>
            </div>

            <UttamKalakarFooter />
            <div className="row" style={{ height: 60, margin: 0, background: 'black' }}>
                <p className="copyright" style={{ fontSize: 15, color: '#999', textAlign: 'center', paddingTop: 18 }}>Copyrights © NRD RUHS Jaipur</p>
            </div>
        </div>
    );
};

export default IssueCertificateReview;
